import React, { useContext } from 'react';
import { timeMapDisplay } from '../../util/timeMap';
import moment from 'moment';
import { AuthContext } from '../../context';
import BookingSlot from './BookingSlot';

const TimeSlot = ({ proSchedule, day, time, modal, shift }) => {
  const { setBookingModal, setShiftModal, setBookingInfoModal } = modal;
  const Auth = useContext(AuthContext);
  const dayString = day.format().split('T')[0];
  const schedule = proSchedule ? proSchedule : {};

  const findEnd = (status) => {
    let end = time;
    let id = `${Auth.proSelected}-${dayString}-${end}`;
    while (schedule[id] && schedule[id].status === status ) {
      end++;
      id = `${Auth.proSelected}-${dayString}-${end}`;
    }

    return timeMapDisplay[end];
  };

  const handleModalOpen = (e) => {
    shift ? setShiftModal(e.target.id) : setBookingModal(e.target.id);
  };

  const makeId = () => {
    return `${Auth.proSelected}-${dayString}-${time}`;
  };

  const renderSchedule = () => {
    const id = makeId();
    const slot = schedule[id];
    if (!slot) {
      return (
        <div 
          onClick={handleModalOpen}
          id={id}
          className={moment().isAfter(day, 'date') ? 'events disabled' : 'events'}
        />
      )
    } else {
      const prevId = `${Auth.proSelected}-${dayString}-${time - 1}`;
      const prevSlot = schedule[prevId] ? schedule[prevId] : {};

      if (slot.status === 'AVAILABLE') {
        if (prevSlot.status !== 'AVAILABLE') {
          const end = findEnd('AVAILABLE');
          return (
            <div 
              onClick={e => setBookingModal(e.target.id)}
              id={id}
              className='events available'
            >
              {`${timeMapDisplay[time].split(' ')[0]} - ${end}`}
            </div>
          )
        }
        return (
          <div 
            onClick={e => setBookingModal(e.target.id)} 
            id={id} 
            className='events available'
          />
        )
      } else if (slot.status === 'BOOKED' || slot.status === 'BOOKED_U') {
        return <BookingSlot bookingId={slot.booking.id} setBookingInfoModal={setBookingInfoModal} time={time} />
      } else if (slot.status === 'UNAVAILABLE') {
        if (prevSlot.status === 'UNAVAILABLE') {
          return <div className='events unavailable' id={id} />  
        } 
        return (
          <div 
            id={id} 
            className='events unavailable'
          >
            <p>unavailable</p>
          </div>
        )
      }

      return (
        <div 
          className='events booked'
          id={id} 
          onClick={() => setBookingInfoModal(slot.booking.id)} 
        />
      )
    }
  };
  
  if (!schedule) {
    return 'loading...';
  }
  return (
    renderSchedule()
  );
};

export default TimeSlot;
