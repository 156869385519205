import gql from 'graphql-tag';

const GET_PROS = gql`
  {
    pros {
      id
      proNumber
      firstName
      lastName
    }
  }
`

export default GET_PROS;