import { gql } from 'apollo-boost';

const EMAIL_RECEIPT = gql`
  mutation EmailReceipt(
    $id: ID
  ) {
    emailReceipt(
      id: $id
    ) {
      success
    }
  }
`;

export default EMAIL_RECEIPT;