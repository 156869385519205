import { gql } from "apollo-boost";

const GET_BOOKING = gql`
  query Booking($id: ID!) {
    booking(id: $id) {
      id
      date
      startTime
      duration
      bookingStatus
      paymentStatus
      subtotal
      promoValue
      tips
      tax
      total
      notes
      charts {
        id
        notes
        followUpNotes
      }
      bookingServices {
        service {
          id
          name
          duration
        }
        user {
          id
          firstName
          lastName
          email
          new
        }
      }
      pro {
        id
        proNumber
        firstName
      }
      user {
        id
        firstName
        lastName
        new
        email
        tel
        relationships {
          id
          relationship
          child {
            id
          }
        }
        address {
          id
          street
          unit
          buzzer
          city
          homeType
          hasStairs
          stairsNotes
          parkingType
          parkingNotes
        }
      }
    }
  }
`;

export default GET_BOOKING;