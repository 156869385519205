import React from 'react';
import { timeMapDisplay, timeMap } from '../../util/timeMap';
import TimeSlot from './TimeSlot';
import './scheduleDisplay.css';
import { useQuery } from '@apollo/react-hooks';
import GET_PROS from '../../graphql/query/pros';
import proMap from '../../util/proMap';
import TimeSlotAll from './TimeSlotAll';

const ScheduleDisplayAll = ({ schedule, pros, modal }) => {
  return (
    <div id='schedule-display' className='card schedule-all'>
      <div className='flex schedule-header-wrapper'>
        {pros.map(proId => (
          <div className='schedule-display-header' key={proId}>
            <div>{proMap[proId].firstName}</div>
          </div>
        ))}
      </div>
      <div className='schedule-display-body'>
        {timeMap.map((time, timeIdx) => {
          return (
            <div className='flex' key={time}>
              <div className='schedule-display-header-time'>
                  {timeIdx % 2 === 0 ? timeMapDisplay[timeIdx] : null}
              </div>
              {pros.map(pro => (
                <TimeSlotAll modal={modal} key={pro} slot={schedule[pro][timeIdx]} schedule={schedule[pro]} />
              ))}
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default ScheduleDisplayAll;
