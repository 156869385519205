import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { AuthContext } from '../../context';
import { useLazyQuery } from '@apollo/react-hooks';
import firebase from '../../Firebase';
import Error from '../../components/Error/Error';
import Loading from '../../components/Loading/Loading';
import LottieCustomize from '../../components/Loading/LottieCustomize';
import GET_PRO from '../../graphql/query/pro';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const Auth = useContext(AuthContext);
  const [getPro, { data }] = useLazyQuery(GET_PRO);
  const history = useHistory();

  useEffect(() => {
    if (Auth.id && Auth.id !== 'tanishadouglas94@hotmail.com') {
      history.push('/schedule');
    }

    if (data && data.pro) {
      localStorage.setItem('id', data.pro.id);
      localStorage.setItem('firstName', data.pro.firstName);
      localStorage.setItem('access', data.pro.type);
      Auth.setState('id', data.pro.id);
      Auth.setState('firstName', data.pro.firstName);
      Auth.setState('access', data.pro.type);
      Auth.setState('proSelected', data.pro.id);

      setLoading(false);
      history.push('/schedule');
    }
  }, [data]);
  
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      setError(false);
      const lowerCaseEmail = email.toLowerCase();
      await firebase.signInWithEmailAndPassword(email, password);
      getPro({
        variables: {
          id: lowerCaseEmail
        }
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
      setError(err.message);
    }
  }

  return (
    <div className='page'>
      <div className='flex col center'>
        <LottieCustomize />
        {loading && <Loading />}
        <h1>Mama Mobile</h1>
        <h5>We are the care specialists for moms.</h5>
        <form className='width-50' onSubmit={handleSubmit}>
          <label>EMAIL</label>
          <input
            type='email'
            required
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder='Email'
          />
          <label>PASSWORD</label>
          <input 
            type='password'
            required
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder='Password'
          />
          <button className='button-outline yellow'>Sign In</button>
          {error && <Error error={error} />}
        </form>
      </div>

    </div>
  )
}

export default LoginForm;
