import React, { useState, useEffect, useContext } from 'react';
import { Drawer, Icon, Card, CardContent, Button, ButtonGroup } from '@material-ui/core';
import { timeMap, timeMapDisplay } from '../../util/timeMap';
import UserCard from '../UserCard/UserCard';
import AddressDetails from './AddressDetails';
import ARRIVE_BOOKING from '../../graphql/mutation/arriveBooking';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Alert from '../Alert/Alert';
import { gql } from "apollo-boost";
import LoadingBasic from '../Loading/LoadingBasic';
import Error from '../Error/Error';
import GET_PROXY from '../../graphql/mutation/proxy';
import MoveModal from './MoveModal';
import CANCEL_BOOKING from '../../graphql/mutation/cancelBooking';
import EMAIL_RECEIPT from '../../graphql/mutation/emailReceipt';
import GET_SCHEDULE_DATE_BY_PRO from '../../graphql/query/scheduleDateByPro';
import { getWeek } from '../../util/getWeek';
import { isWithin24Hours } from '../../util/date';
import GET_BOOKING from '../../graphql/query/booking';
import { AuthContext } from '../../context';


const FAKEBOOKING = {
  user: {
    address: {}
  },
  bookingServices: [
    {
      service: {
        id: 'physio_30'
      }
    }
  ],
  promo: {},
}

const BookingInfoModal = ({ setBookingInfoModal, id, schedule }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [sentReceipt, setSentReceipt] = useState(false);
  const [users, setUsers] = useState({});
  const [arrivalAlert, setArrivalAlert] = useState(false);
  const [cancelAlert, setCancelAlert] = useState(false);
  const [getProxy, proxy] = useMutation(GET_PROXY);
  const [emailReceipt, receipt] = useMutation(EMAIL_RECEIPT);
  const [moveModal, setMoveModal] = useState(false);
  const BOOKING = useQuery(GET_BOOKING, { variables: { id }});
  const Auth = useContext(AuthContext);
  
  const [booking, setBooking] = useState(FAKEBOOKING);
  const endIdx = booking.startTime + Math.floor((booking.duration + 60) / 15);
  const promo = booking.promo ? booking.promo : {}
  const promoValue = promo.value ? promo.value : 0;
  console.log(booking);

  const [ arriveBooking, arrivedBooking ] = useMutation(ARRIVE_BOOKING,
    {
      update(cache, arrivedBooking) {
        if (arrivedBooking.data.arriveBooking.success) {
          const { booking } = cache.readQuery({ 
            query: GET_BOOKING,
            variables: {
              id,
            }
          });

          cache.writeQuery({
            query: GET_BOOKING,
            variables: {
              id,
            },
            data: { booking: {
              ...booking,
              paymentStatus: 'PAID',
              bookingStatus: 'ARRIVED',
            }},
          });

          setLoading(false);
        } else {
          setLoading(false);
          setError(arrivedBooking.data.arriveBooking.message);
        }
      },
    }
  );

  const [cancelBooking, cancelledBooking] = useMutation(CANCEL_BOOKING, 
    {
      update(cache, cancelledBooking) {
        if (cancelledBooking.data.cancelBooking.success) {
          const week = getWeek(booking.date);
          const { scheduleDateByPro } = cache.readQuery({ 
            query: GET_SCHEDULE_DATE_BY_PRO,
            variables: {
              id: booking.pro.id,
              start: week[0],
              end: week[1],
            }
          });

          const ids = {};
          const blocks = Math.floor(booking.duration / 15) + 4;

          for (let i = booking.startTime; i < (booking.startTime + blocks); i++) {
            ids[`${booking.pro.id}-${booking.date}-${i}`] = true;
          };

          const schedules = scheduleDateByPro.map(schedule => {
            const idArr = Object.keys(ids);
            for (let i = 0; i < idArr.length; i++ ) {
              let id = idArr[i];
              if (id === schedule.id) {
                schedule.status = 'UNAVAILABLE';
                // schedule.booking = null;
                delete ids[id];
                return schedule;
              };

              break;
            };

            return schedule;
          });

          cache.writeQuery({
            query: GET_SCHEDULE_DATE_BY_PRO,
            variables: {
              id: booking.pro.id,
              start: week[0],
              end: week[1],
            },
            data: { scheduleDateByPro: schedules },
          });

          setLoading(false);
          setCancelAlert(false);
          setBookingInfoModal(false);
        } else {
          setLoading(false);
          setError(cancelledBooking.data.cancelBooking.message);
        }
      }
    }
  );

  useEffect(() => {
    if (id) {
      setOpen(true);
      setLoading(true);

      if (BOOKING.data) {
        setLoading(false);
        setBooking(BOOKING.data.booking);
  
        const userServices = {};
        BOOKING.data.booking.bookingServices.map(bookingService => {
          if (userServices[bookingService.user.id]) {
            userServices[bookingService.user.id].services.push(bookingService.service);
          } else {
            userServices[bookingService.user.id] = {
              user: bookingService.user,
              services: [bookingService.service]
            };
          }
        });
  
        setUsers(userServices);
      } else if (BOOKING.error) {
        setLoading(false);
        setError(BOOKING.error);
      }
    } else {
      setOpen(false);
    }
  }, [id, BOOKING]);

  const handleArrival = async () => {
    try {
      setLoading(true);
      setError(false);
      setArrivalAlert(false);
      arriveBooking({
        variables: {
          id: booking.id,
        }
      });
    } catch (err) {
      setLoading(false);
      setError(err);
      console.log(err);
    }
  }

  const handleEmailReceipt = async () => {
    try {
      setSentReceipt(true);
      emailReceipt({
        variables: {
          id: booking.id,
        }
      });
    } catch (err) {
      setError(err);
      console.log(err);
    }
  }

  const handleCall = async () => {
    setLoading(true);
    setError(false);

    const proxy = await getProxy({
      variables: {
        id: booking.id,
      }
    });

    if (proxy.data.proxy.success) {
      setLoading(false);
      window.open(`tel:${proxy.data.proxy.message}`);
    } else {
      setLoading(false);
      setError(proxy.data.proxy.message);
    }
  }

  const handleCancel = async () => {
    setLoading(true);
    setError(false);
    cancelBooking({
      variables: {
        id: booking.id,
        status: Auth.access === 'admin' ? 'USER' : 'PRO',
      }
    });
  }

  const endTime = (booking.bookingServices[0].service.id.split('_')[0] === 'rmt') ? Math.floor(booking.startTime + booking.duration / 15 + 4) : Math.floor(booking.startTime + booking.duration / 15 + 1);

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={open}
    >
      {loading && <LoadingBasic />}
      {error && <Error error={error} />}
      {moveModal && <MoveModal booking={booking} open={moveModal} setOpen={setMoveModal} />}
      <Alert 
        open={arrivalAlert} 
        handleClose={() => setArrivalAlert(false)} 
        handleSubmit={handleArrival} 
        title='Did you arrive?' 
        description='Please confirm your arrival!'
      />
      <Alert 
        open={cancelAlert} 
        handleClose={() => setCancelAlert(false)} 
        handleSubmit={handleCancel} 
        title='Cancel Appointment' 
        description='Please confirm if you would like to cancel the appointment. Please note that cancelling the appointment within the 24 hours will incur a $10 charge.'
      />
      <div className='modal-header'>
        <h3>Booking Info</h3>
        <Icon className='clickable' onClick={() => setBookingInfoModal(false)}>close</Icon>
      </div>
      <ButtonGroup id='booking-info-button-group' color="secondary">
        <Button disabled={(booking.bookingStatus === 'ARRIVED') ? true : false} onClick={() => setArrivalAlert(true)}>{booking.bookingStatus === 'ARRIVED' ? 'Arrived' : 'Arrive'}</Button>
        <Button disabled={(booking.bookingStatus === 'ARRIVED') ? true : false} onClick={() => setMoveModal(true)}>Move</Button>
        {Auth.access === 'admin' ? <Button disabled={(booking.bookingStatus === 'ARRIVED') ? true : false} onClick={() => setCancelAlert(true)}>Cancel</Button> : isWithin24Hours(booking.date, booking.startTime) && <Button disabled={(booking.bookingStatus === 'ARRIVED') ? true : false} onClick={() => setCancelAlert(true)}>Cancel</Button>}
        <Button disabled={sentReceipt} onClick={handleEmailReceipt}>Receipt</Button>
      </ButtonGroup>
      <Card className='modal-body'>
        <CardContent>
          <div className='modal-body-header'>
            <p>
              {`${booking.user.firstName} ${booking.user.lastName}`}
            </p>
            <div className='flex margin-left-5'>
              {booking.user.new && <Icon color="primary" fontSize="small">fiber_new</Icon>}
              {booking.bookingStatus === 'ARRIVED' && <Icon color="primary" fontSize="small">drive_eta</Icon>}
              {booking.paymentStatus === 'PAID' && <Icon color="primary" fontSize="small">attach_money</Icon>}
              {/* {booking.user.intakes[0].complete && <Icon color="primary" fontSize="small">assignment_turned_in_icon</Icon>} */}
              {/* {<Icon color="primary" fontSize="small">chat</Icon>} */}
            </div>
          </div>
          <div className='item-icon'>
            <Icon>calendar_today</Icon>
            <p>{booking.date}</p>
          </div>
          <div className='item-icon'>
            <Icon>access_time</Icon>
            <p>
              {`${timeMapDisplay[booking.startTime]} - ${timeMapDisplay[endTime]}`}
            </p>
          </div>
          {booking.bookingStatus !== 'ARRIVED' && (
            <div className='item-icon'>
              <Icon>call</Icon>
              <p onClick={handleCall} className='link'>{`Call ${booking.user.firstName}`}</p>
            </div>
          )}
          {(Auth.access === 'admin' || Auth.id === 'pmacneil@bell.net') &&
            <div className='item-icon'>
              <Icon>call</Icon>
              <p>{booking.user.tel}</p> 
            </div>
          }
          {Auth.access === 'admin' && <div className='item-icon'>
            <Icon>email</Icon>
            <p>
              {booking.user.email}
            </p>
          </div>}
          <div className='item-icon'>
            <Icon>home</Icon>
            <p>
              {booking.user.address && (booking.user.address.unit ? `${booking.user.address.unit} - ${booking.user.address.street}, ${booking.user.address.city}` : `${booking.user.address.street}, ${booking.user.address.city}`)}
            </p>
          </div>
          <AddressDetails address={booking.user.address} />
          <h4>Services:</h4>
          {booking.bookingServices.map(service => (
            <p key={service.service.id} className='modal-body-text'>
              {`${service.service.name} - ${service.service.duration} min`}
            </p>
          ))}
        </CardContent>
        <CardContent>
          <div className='line-item'>
            <div>Subtotal</div>
            <div>{booking.subtotal}</div>
          </div>
          <div className='line-item'>
            <div>Promo</div>
            <div>{booking.promoValue ? `-${booking.promoValue}` : '0'}</div>
          </div>
          <div className='line-item'>
            <div>Tips</div>
            <div>{booking.tips || 0}</div>
          </div>
          <div className='line-item'>
            <div>Tax</div>
            <div>{booking.tax}</div>
          </div>
          <div className='line-item'>
            <div>Total</div>
            <div>{booking.total}</div>
          </div>
          {booking.notes && (
            <div className='margin-top-20'>
              <h4>Notes</h4>
              <p className='modal-body-text'>
                {booking.notes}
              </p>
            </div>
          )}
        </CardContent>
      </Card>
      {Object.keys(users).map(id => {
        return (
          <UserCard key={id} user={users[id].user} services={users[id].services} booking={booking} />
        )
      })}
      <Card className='glossary-card'>
        <CardContent>
          <h5>Icon Glossary</h5>
          <div className='flex'>
            <Icon color="primary" fontSize="small">fiber_new</Icon>
            <p>New</p>
          </div>
          <div className='flex'>
            <Icon color="primary" fontSize="small">drive_eta</Icon>
            <p>Arrived</p>
          </div>
          <div className='flex'>
            <Icon color="primary" fontSize="small">attach_money</Icon>
            <p>Paid</p>
          </div>
          <div className='flex'>
            <Icon color="primary" fontSize="small">assignment_turned_in_icon</Icon>
            <p>Intake Form Completed</p>
          </div>
          <div className='flex'>
            <Icon color="primary" fontSize="small">chat</Icon>
            <p>Follow-up Completed</p>
          </div>   
        </CardContent>
      </Card>
    </Drawer>
  )
}

export default BookingInfoModal;