import { gql } from 'apollo-boost';

const CANCEL_BOOKING = gql`
  mutation CancelBooking(
    $id: ID
    $status: String
  ) {
    cancelBooking(
      id: $id
      status: $status
    ) {
      success
      message
    }
  }
`;

export default CANCEL_BOOKING;