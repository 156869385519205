import React from 'react';

const RmtFirstVisitFollowUp = ({ 
  user,
  FOLLOWUP, 
  followUpTime, 
  setFollowUpTime, 
  followUpNotes, 
  setFollowUpNotes, 
  recommendations,
  setRecommendations,
  focusArea,
  setFocusArea,
  pro,
 }) => (
  <>
    <label>Treatment Goal</label>
    <textarea
      required
      placeholder={`It was awesome meeting you - thank you for trusting me to be part of your self-care routine and inviting me into your home. Our current goal of treatment for you is ...`}
      rows="4" 
      value={followUpNotes}
      onChange={e => setFollowUpNotes(e.target.value)}
    />
    <label>Areas of focus</label>
    <textarea
      required
      placeholder={`Based on my findings during your assessment, the areas we will focus on during our treatments together are currently...`}
      rows="4" 
      value={focusArea}
      onChange={e => setFocusArea(e.target.value)}
    />
    <label>Exercises/recommendations</label>
    <textarea
      required
      placeholder={`Here are a few things that I would like you to squeeze in time for each week to ensure you are continuing to progress:`}
      rows="6" 
      value={recommendations}
      onChange={e => setRecommendations(e.target.value)}
    />
    <label>Select follow-up date:</label>
    <select required name="follow-up-date" value={followUpTime} onChange={e => setFollowUpTime(e.target.value)}>
      <option value="">--Please choose an option--</option>
      {FOLLOWUP.map(item => <option key={item} value={item}>{item}</option>)}
    </select>
    <div>
      <h5>{`Follow-up email to ${user.firstName}👇`}</h5>
      <p>{`Hi ${user.firstName}`}</p>
      <p>It was awesome meeting you - thank you for trusting me to be part of your self-care routine and inviting me into your home.</p>
      <p>{`Our current goal of treatment for you is `}<b>{followUpNotes}</b>.</p>
      <p>{`Based on my findings during your assessment, the areas we will focus on during our treatments together are currently `}<b>{focusArea}</b>.</p>
      <p>Here are a few things that I would like you to squeeze in time for each week to ensure you are continuing to progress:</p>
      {recommendations.split('\n').map((item, idx) => <p key={idx}><b>{item}</b></p>)}
      <p>{`I would like to see you again in `}<b>{followUpTime}</b>{` to continue our treatment plan together.`}</p>
      <p>We are pretty booked up so if you don’t see anything let me know and we can find a new date/time! Also, let me know if you have any questions.</p>
      <p>Warmly,</p>
      <p>{pro.firstName}</p>
    </div>
  </>
);

export default RmtFirstVisitFollowUp;