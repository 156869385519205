import moment from 'moment';

const getWeek = (day) => {
  const start = moment(day).startOf('week');
  const end = moment(day).endOf('week');

  return [start.format().split('T')[0], end.format().split('T')[0]];
};

export {
  getWeek
}
