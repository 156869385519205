import { gql } from 'apollo-boost';

const GET_USER = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      tel
      stripe
      address {
        id
        unit
        street
        city
        province
      }
      charts {
        id
        notes
        followUpNotes
        followUpDate
        followUpTime
        booking {
          id
          date
        }
        pro {
          id
          firstName
          lastName
        }
      }
      intakes {
        id 
        complete
        createdAt
      }
      bookings {
        id
        date
        bookingStatus
      }
    }
  }
`;

export default GET_USER;