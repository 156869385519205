import React, { useState, useEffect, useContext } from 'react';
import { Drawer, Icon, Card, CardContent, Button } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { v4 as uuidv4 } from 'uuid';
import { timeMap } from '../../util/timeMap';
import ADD_BOOKING from '../../graphql/mutation/addBooking';
import UserServicesCard from './UserServicesCard';
import PromoSelector from '../Selector/PromoSelector';
import Error from '../Error/Error';
import { AuthContext } from '../../context';
import GET_SCHEDULE_DATE_BY_PRO from '../../graphql/query/scheduleDateByPro';
import { getWeek } from '../../util/getWeek';
import LoadingBasic from '../Loading/LoadingBasic';
import DELETE_SHIFT from '../../graphql/mutation/deleteShift';

const BookingModal = ({ setBookingModal, id }) => {
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState('');
  const [pro, setPro] = useState('');
  const [promo, setPromo] = useState('');
  const [open, setOpen] = useState(false);
  const [notes, setNotes] = useState('');
  const Auth = useContext(AuthContext);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const [user, setUser] = useState(null);
  const [services, setServices] = useState([]);
  //optional
  const [select1, setSelect1] = useState(false);
  const [user1, setUser1] = useState(null);
  const [services1, setServices1] = useState([]);

  //optional
  const [select2, setSelect2] = useState(false);
  const [user2, setUser2] = useState(null);
  const [services2, setServices2] = useState([]);

  const [deleteShift, deletedShift] = useMutation(DELETE_SHIFT,
    {
      update(cache, deletedShift) {
        console.log(deletedShift);
        if (deletedShift.data.deleteShift.success) {
          const week = getWeek(date);
          const { scheduleDateByPro } = cache.readQuery({ 
            query: GET_SCHEDULE_DATE_BY_PRO,
            variables: {
              id: pro,
              start: week[0],
              end: week[1],
            }
          });

          const newSchedule = scheduleDateByPro.filter(schedule => schedule.id !== id);
          cache.writeQuery({
            query: GET_SCHEDULE_DATE_BY_PRO,
            variables: {
              id: pro,
              start: week[0],
              end: week[1],
            },
            data: { scheduleDateByPro: newSchedule },
          });

          setLoading(false);
          setBookingModal(false);
        } else {
          setLoading(false);
          setError(deletedShift.data.deleteShift.message);
        }
      }
    }
  );

  const [addBooking, bookingRes] = useMutation(ADD_BOOKING,
    {
      update(cache, addedBooking) {
        if (addedBooking.data.addBooking.success) {
          const week = getWeek(date);
          const { scheduleDateByPro } = cache.readQuery({ 
            query: GET_SCHEDULE_DATE_BY_PRO,
            variables: {
              id: pro,
              start: week[0],
              end: week[1],
            }
          });

          const newSchedule = [...scheduleDateByPro];

          let alteredSchedule = {};
          for (let i = 0; i < addedBooking.data.addBooking.schedule.length; i++) {
            const sched = addedBooking.data.addBooking.schedule[i];

            if (sched.status === 'BOOKED_U') {
              newSchedule.push(sched);
            } else {
              alteredSchedule[sched.id] = sched;
            }
          }

          if (Object.keys(alteredSchedule).length > 0) {
            newSchedule.forEach(schedule => {
              if (alteredSchedule[schedule.id]) {
                schedule = alteredSchedule[schedule.id];
              }
            });
          }

          cache.writeQuery({
            query: GET_SCHEDULE_DATE_BY_PRO,
            variables: {
              id: pro,
              start: week[0],
              end: week[1],
            },
            data: { scheduleDateByPro: newSchedule },
          });

          setLoading(false);
          setBookingModal(false);
        } else {
          setLoading(false);
          setError(addedBooking.data.addBooking.message);
        }
      }
    }
  );

  useEffect(() => {
    if (id) {
      setOpen(true);
      const idArr = id.split('-');
      const pro = idArr[0];
      const date = `${idArr[1]}-${idArr[2]}-${idArr[3]}`;
      const start = Number(idArr[4]);
      setPro(pro);
      setDate(date);
      setTime(start);
    } else {
      setOpen(false);
    }
  }, [id]);

  const handleDeleteShift = () => {
    setLoading(true);
    setError(false);
    deleteShift({ 
      variables: {
        id,
      } 
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!user) {
      setLoading(false);
      setError('Please choose a mama!');
      return;
    } 

    if (Object.entries(services).length === 0) {
      setLoading(false);
      setError('Please choose a service!');
      return;
    }

    const bookingId = uuidv4();
    const booking = {
      id: bookingId,
      date,
      startTime: Number(time),
      notes,
      proId: pro,
      bookingSource: `portal-${Auth.id}`,
      userId: user.id,
      stripe: user.stripe,
      subtotal: 0,
      duration: 0,
      proAmount: 0,
      services: [],
      users: [user.id],
    };

    console.log(booking);
    
    Object.entries(services).map(serviceTuple => {
      const service = serviceTuple[1];
      booking.subtotal += service.price;
      booking.duration += service.duration;
      booking.proAmount += service.split;
      const data = {
        serviceId: service.id,
        bookingId: bookingId,
        userId: user.id,
      };
      booking.services.push(data);
    });

    if (user1) {
      booking.users.push(user1.id);
      Object.entries(services1).map(serviceTuple => {
        const service = serviceTuple[1];
        booking.subtotal += service.price;
        booking.duration += service.duration;
        booking.proAmount += service.split;
        const data = {
          serviceId: service.id,
          bookingId: bookingId,
          userId: user1.id,
        };
        booking.services.push(data);
      });
    }

    if (user2) {
      booking.users.push(user2.id);
      Object.entries(services2).map(serviceTuple => {
        const service = serviceTuple[1];
        booking.subtotal += service.price;
        booking.duration += service.duration;
        booking.proAmount += service.split;
        const data = {
          serviceId: service.id,
          bookingId: bookingId,
          userId: user2.id,
        };
        booking.services.push(data);
      });
    }

    if (promo) {
      booking.promoValue = promo.value;
      booking.promoId = promo.id;
      booking.tax = services[0].category === 'rmt' ? Number(((booking.subtotal - promo.value) * 0.13).toFixed(2)) : 0;
      booking.total = booking.subtotal - promo.value + booking.tax;
    } else {
      booking.tax = services[0].category === 'rmt' ? Number((booking.subtotal * 0.13).toFixed(2)) : 0;
      booking.total = booking.subtotal + booking.tax;
    }

    addBooking({ 
      variables: booking,
    });
  }

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={open}
    >
      {loading && <LoadingBasic />}
      <div className='modal-header'>
        <h3>New Booking</h3>
        <Icon className='clickable' onClick={() => setBookingModal(false)}>close</Icon>
      </div>
      <Card>
        <CardContent>
          <UserServicesCard user={user} setUser={setUser} services={services} setServices={setServices} />
          {!select1 && <div onClick={() => setSelect1(true)} className='link'>Add Another User</div>}
          {select1 && <UserServicesCard user={user1} setUser={setUser1} services={services1} setServices={setServices1} />}
          {(select1 && !select2) && <div onClick={() => setSelect2(true)} className='link'>Add Another User</div>}
          {select2 && <UserServicesCard user={user2} setUser={setUser2} services={services2} setServices={setServices2} />}
          <form onSubmit={handleSubmit}>
            <label className='margin-top-20'>Date</label>
            <input required type="date" value={date} onChange={e => setDate(e.target.value)} />
            <label>Time</label>
            <select
              value={time}
              onChange={(e) => setTime(e.target.value)}
              required
            >
              {timeMap.map(( time, idx ) => (
                <option key={idx} value={idx}>{time}</option>
              ))}
            </select>
            <PromoSelector promo={promo} setPromo={setPromo} />
            <label>Notes</label>
            <textarea rows="4" value={notes} onChange={(e) => setNotes(e.target.value)}/>
            {error && <Error error={error} />}
            <Button disabled={loading} type="submit" color="secondary" variant="contained">Save Booking</Button>
            <div className='link' onClick={handleDeleteShift}>Delete Shift</div>
          </form>
        </CardContent>
      </Card>
    </Drawer>
  );
}

export default BookingModal;
