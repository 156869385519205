import React, { useEffect, useState } from 'react';
import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Circle } from "react-google-maps";
import Autocomplete from 'react-google-autocomplete';

const styles = {
  stroke: {
    // strokeOpacity: 0.8,
    strokeWeight: 1,
    strokeColor: 'red',
    fillColor: "red",
    fillOpacity: 0.1,
  },
  inputWrapper: {
    width: '350px',
    marginTop: '40px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}

const MapRadius = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBDAZmdNIn0u8q97UClMqPC4DZ--GHs-gk&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `calc(100vh - 200px)` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const [address, setAddress] = useState('');
  const [radius, setRadius] = useState(0);
  const [lat, setLat] = useState(43.65037580000001);
  const [lng, setLng] = useState(-79.3807063);

  return (
    <div style={styles.root}>
      <div style={styles.inputWrapper} >
        <label>Address</label>
        <Autocomplete
          value={address.formatted_address}
          onChange={(e) => setAddress(e.target.value)}
          onPlaceSelected={(address) => {
            console.log(address);
            console.log(address.geometry.location.lat());
            setLat(address.geometry.location.lat());
            setLng(address.geometry.location.lng());
            setAddress(address);
          }}
          required
          name='address'
          types={['address']}
          placeholder=''
          componentRestrictions={{country: "ca"}}
        />
        <label>Radius</label>
        <input value={radius} onChange={e => setRadius(e.target.value)} />
      </div>
      <GoogleMap
        defaultZoom={9}
        center={{ lat, lng }}
      >
        <Circle 
          center={{ lat, lng }}
          radius={Number(radius) * 1000} 
          editable={false} 
          visible 
          options={styles.stroke}
        />
      </GoogleMap>
    </div>
  );
})

export default MapRadius;