import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  chart: {
    width: '100%',
  }
}));

const ChartItem = ({ chart }) => {
  const classes = useStyles();
  const heading = `Booking: ${chart.booking.date} with ${chart.pro.firstName} ${chart.pro.lastName}`;

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography className={classes.heading}>{heading}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div className={classes.chart}>
          {chart.notes.split('\n').map(item => {
            if (item === '') {
              return <br style={{marginBottom: '20px'}} />
            }

            return <p style={{marginBottom: 0}}>{item}</p>;
          })}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
};

export default ChartItem;