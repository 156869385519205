import React, { useState, useContext } from 'react';
import { Drawer, Icon, Card, CardContent, Button } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { timeMap } from '../../util/timeMap';
import Error from '../Error/Error';
import MOVE_BOOKING from '../../graphql/mutation/moveBooking';
import GET_SCHEDULE_DATE_BY_PRO from '../../graphql/query/scheduleDateByPro';
import { getWeek } from '../../util/getWeek';
import GET_BOOKING from '../../graphql/query/booking';
import LoadingBasic from '../Loading/LoadingBasic';
import { AuthContext } from '../../context';
import ProSelector from '../Selector/ProSelector';

const MoveModal = ({ setOpen, booking, open }) => {
  const [date, setDate] = useState('');
  const [time, setTime] = useState(0);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pro, setPro] = useState('');
  const Auth = useContext(AuthContext);
  const [moveBooking, movedBooking] = useMutation(MOVE_BOOKING, 
    {
      update(cache, movedBooking) {
        if (movedBooking.data.moveBooking.success) {
          const weekOld = getWeek(booking.date);
          const weekNew = getWeek(date);

          const { scheduleDateByPro } = cache.readQuery({ 
            query: GET_SCHEDULE_DATE_BY_PRO,
            variables: {
              id: booking.pro.id,
              start: weekOld[0],
              end: weekOld[1],
            }
          });

          const bookingQuery = cache.readQuery({
            query: GET_BOOKING,
            variables: {
              id: booking.id,
            }
          });

          const newBooking = {
            ...bookingQuery.booking,
            date,
            startTime: Number(time),
          }

          const schedules = [];
          const updateSchedule = {};
          const updateScheduleNew = {};
          const schedulesNew = [];

          // add schedules to be removed in the updateSchedule object
          const blocks = Math.floor(booking.duration / 15) + 4;

          for (let i = booking.startTime; i < (booking.startTime + blocks); i++) {
            updateSchedule[`${booking.pro.id}-${booking.date}-${i}`] = 'remove';
          };


          // add updated schedule to the updateSchedule object
          for (let i = 0; i < movedBooking.data.moveBooking.schedule.length; i++) {
            const schedule = movedBooking.data.moveBooking.schedule[i];

            if (weekNew[0] !== weekOld[0]) {
              if (schedule.status === 'BOOKED_U') {
                schedulesNew.push(schedule);
              } else {
                updateScheduleNew[schedule.id] = schedule;
              }
            } else {
              if (schedule.status === 'BOOKED_U') {
                schedules.push(schedule);
              } else {
                updateSchedule[schedule.id] = schedule;
              }
            }
          }

          scheduleDateByPro.forEach(schedule => {
            if (!updateSchedule[schedule.id]) {
              schedules.push(schedule);
            } else if (updateSchedule[schedule.id] === 'remove') {
              if (schedule.status === 'BOOKED') {
                schedule.status = 'AVAILABLE';
                schedule.booking = null;
                schedules.push(schedule);
              }
            } else {
              schedules.push(updateSchedule[schedule.id]);
            }
          });

          cache.writeQuery({
            query: GET_SCHEDULE_DATE_BY_PRO,
            variables: {
              id: booking.pro.id,
              start: weekOld[0],
              end: weekOld[1],
            },
            data: { scheduleDateByPro: schedules },
          });

          cache.writeQuery({
            query: GET_BOOKING,
            variables: {
              id: booking.id,
            },
            data: { booking: newBooking },
          });

          if (weekNew[0] !== weekOld[0]) {
            const queryJSON = JSON.stringify({
              end: weekNew[1],
              id: booking.pro.id,
              start: weekNew[0],
            })

            const newWeekSchedule = cache.data.data.ROOT_QUERY[`scheduleDateByPro(${queryJSON})`];

            if (newWeekSchedule) {
              const { scheduleDateByPro } = cache.readQuery({ 
                query: GET_SCHEDULE_DATE_BY_PRO,
                variables: {
                  id: booking.pro.id,
                  start: weekNew[0],
                  end: weekNew[1],
                }
              });

              const newSchedule = [...scheduleDateByPro, ...schedulesNew];

              if (Object.keys(updateScheduleNew)) {
                newSchedule.forEach(schedule => {
                  if (updateScheduleNew[schedule.id]) {
                    schedule = updateScheduleNew[schedule.id];
                  }
                });
              }

              cache.writeQuery({
                query: GET_SCHEDULE_DATE_BY_PRO,
                variables: {
                  id: booking.pro.id,
                  start: weekNew[0],
                  end: weekNew[1],
                },
                data: { scheduleDateByPro: newSchedule },
              });
            }
          }

          setLoading(false);
          setOpen(false);
        } else {
          setLoading(false);
          setError(movedBooking.data.moveBooking.message);
        }
      }
    }
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    console.log({id: booking.id,
      date,
      time: Number(time),
      pro,
    })
    try {
      await moveBooking({
        variables: {
          id: booking.id,
          date,
          time: Number(time),
          pro,
        }
      });
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  }

  const handleProSelect = (e) => {
    setPro(e.target.value);
  }

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={open}
    >
      {loading && <LoadingBasic />}
      <div className='modal-header'>
        <h3>Move Booking To</h3>
        <Icon className='clickable' onClick={() => setOpen(false)}>close</Icon>
      </div>
      <Card>
        <form onSubmit={handleSubmit}>
          <CardContent>
            {Auth.access === 'admin' && <ProSelector handleSelect={handleProSelect} selected={pro} />}
            <label className='margin-top-20'>Date</label>
            <input required type="date" value={date} onChange={e => setDate(e.target.value)} />
            <label>Time</label>
            <select
              value={time}
              onChange={(e) => setTime(e.target.value)}
              required
            >
              {timeMap.map(( time, idx ) => (
                <option key={idx} value={idx}>{time}</option>
              ))}
            </select>
            {error && <Error error={error} />}
            <Button type="submit" color="secondary" variant="contained">Move Booking</Button>
          </CardContent>
        </form>
      </Card>
    </Drawer>
  );
}

export default MoveModal;
