import { gql } from "apollo-boost";

const GET_SCHEDULE_DATE_BY_PRO = gql`
  query ScheduleDateByPro(
    $id: ID
    $start: String
    $end: String
  ) {
    scheduleDateByPro(
      id: $id
      start: $start
      end: $end
    ) {
      id
      date
      time
      status
      booking {
        id
      }
    }
  }
`;

export default GET_SCHEDULE_DATE_BY_PRO;