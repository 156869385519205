import React from 'react';
import { timeMapDisplay } from '../../util/timeMap';
import { Icon } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import GET_BOOKING from '../../graphql/query/booking';

const BookingSlot = ({ setBookingInfoModal, bookingId, time, all }) => {
  const { data, loading, error } = useQuery(GET_BOOKING, { variables: { id: bookingId }});

  if (loading) return <div className='events booked' />;
  if (error) return '...error';

  const renderBookingSlot =  () => {
    const { startTime, bookingStatus, paymentStatus, duration, user, bookingServices, id } = data.booking;
    const end = (bookingServices[0].service.id.split('_')[0] === 'rmt') ? Math.floor(startTime + duration / 15 + 4) : Math.floor(startTime + duration / 15 + 1);
    if (startTime === time) {
      return (<div 
        className={all ? 'events booked all' : 'events booked' }
        id={id} 
        onClick={() => setBookingInfoModal(id)}
      >
        <div className='booking-info'>
          <p><b>{`${timeMapDisplay[startTime].split(' ')[0]} - ${timeMapDisplay[end]}`}</b></p>
          <p><b>{user.firstName}</b></p>
          <div className='flex margin-top-5'>
            {user.new && <Icon color="primary" fontSize="small">fiber_new</Icon>}
            {bookingStatus === 'ARRIVED' && <Icon color="primary" fontSize="small">drive_eta</Icon>}
            {paymentStatus === 'PAID' && <Icon color="primary" fontSize="small">attach_money</Icon>}
          </div>
          <div className='booking-info-services'>
            {bookingServices && bookingServices.map(service => (
              <p key={service.service.id}>{`${service.service.name} - ${service.service.duration} min`}</p>
            ))}
          </div>
        </div>
      </div>)
    } else {
      return (<div 
        className={all ? 'events booked all' : 'events booked' }
        id={id} 
        onClick={() => setBookingInfoModal(id)} 
      />)
    }
  }

  return (
    renderBookingSlot()
  )     
};

export default BookingSlot;
