import { gql } from 'apollo-boost';

const ADD_CHART = gql`
  mutation AddChart(
    $proId: ID
    $bookingId: ID
    $userId: ID
    $notes: String
    $followUpNotes: String
    $followUpDate: String
    $followUpTime: String
    $userFirstName: String
    $proFirstName: String
    $email: String
    $followUpType: String
    $recommendations: String
    $focusArea: String
  ) {
    addChart(
      proId: $proId
      bookingId: $bookingId
      userId: $userId
      notes: $notes
      followUpNotes: $followUpNotes
      followUpDate: $followUpDate
      followUpTime: $followUpTime
      userFirstName: $userFirstName
      proFirstName: $proFirstName
      email: $email
      followUpType: $followUpType
      recommendations: $recommendations
      focusArea: $focusArea
    ) {
      success
      message
      chart {
        notes
      }
    }
  }
`;

export default ADD_CHART;