import { gql } from "apollo-boost";

const GET_PRO = gql`
  query Pro($id: ID!) {
    pro(id: $id) {
      id
      firstName
      lastName
      email
      proNumber
      type
    }
  }
`;

export default GET_PRO;