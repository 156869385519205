import React, { useState } from 'react';
import GET_PROMOS from '../../graphql/query/promos';
import { useQuery } from '@apollo/react-hooks';

const PromoSelector = ({ promo, setPromo }) => {
  const { loading, error, data } = useQuery(GET_PROMOS);
  const [selected, setSelected] = useState('');

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  const handleSelect = (e) => {
    setSelected(e.target.value);
    setPromo(data.promos[e.target.value]);
  }
  
  return (
    <div className='margin-top-20'>
      <label>SELECT A PROMO:</label>
      <select
        value={selected}
        onChange={handleSelect}
      >
        <option value={''} />
        {data.promos.map((item, idx) => (
          <option key={item.id} value={idx}>{`${item.id} - $${item.value}`}</option>
        ))}
      </select>
    </div>
  );
};

export default PromoSelector;
