const proMap = {
  "jiwan.alisha@gmail.com": {
    id: "jiwan.alisha@gmail.com",
    firstName: "Alisha",
    lastName: "Jiwan",
    email: "jiwan.alisha@gmail.com",
    tel: "4189078167",
    gender: "female",
    birthday: "1987-08-06",
    type: "rmt",
    status: "active",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/q_20/v1572370712/headshots/alicia_horizontal_jnrpdg.jpg",
    bio: "After having experienced the benefits of massage first-hand, I decided to enrol into the Registered Massage Therapy (RMT) program where I learned massage treatments for prenatal, infant, stroke, sport therapy, respiratory issues, migraines, stress and various uses of hydrotherapy to name a few.",
    lat: "43.676128",
    long: "-79.811715",
    distance: 10,
    proNumber: "X773",
    stripe: "acct_1H4YnpDC2xEfvIQr",
    auth: "5JwQEkqtRveZu7D6IspCZAokvHs2",
  },
  "pmacneil@bell.net": {
    id: "pmacneil@bell.net",
    firstName: "Patricia",
    lastName: "MacNeil",
    email: "pmacneil@bell.net",
    tel: "6135234962",
    gender: "female",
    birthday: "1950-11-27",
    type: "rmt",
    status: "active",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1556065743/team/Patricia",
    bio: "I’ve been an RMT since 1986! I designed and supervised pre and perinatal and newborn massage therapy training programs for 5 massage therapy colleges in the Ottawa area. These internships were held at the Civic and General campuses of the Ottawa Hospital system for 15 years.",
    lat: "45.368703",
    long: "-75.672161",
    distance: 25,
    proNumber: "B445",
    stripe: "acct_1H4YnpDC2xEfvIQr",
    auth: "xb01jS621HhBglsjBoA8A3W8crd2",
  },
  "daynaphillips01@gmail.com": {
    id: "daynaphillips01@gmail.com",
    firstName: "Dayna",
    lastName: "Phillips",
    email: "daynaphillips01@gmail.com",
    tel: "4165503420",
    gender: "female",
    birthday: "1968-05-22",
    type: "rmt",
    status: "active",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1582742537/dayna.jpg",
    bio: "I’m at Sutherland Chan graduate with experience in breast massage, treating mastectomy scars and lymphedema. My massage philosophy is one of collaboration. I always strive to put my patient at ease with the goal of designing the perfect treatment, together!",
    lat: "43.669199",
    long: "-79.377301",
    distance: 10,
    proNumber: "Y341",
    stripe: "acct_1H4tySGSFDuzhcV5",
    auth: "2g0xYKWcoJP3GlDS23I6qcMgO4w2",
  },
  "cherry@mamamobilemassage.com": {
    id: "cherry@mamamobilemassage.com",
    email: "cherry@mamamobilemassage.com",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1580627075/welcome/_K3A9907_1_eliq5n.jpg",
    firstName: "Cherry",
    lastName: "Xu",
    status: "active",
    stripe: "acct_1Gqkc1BTaYk95Ny0",
    tel: '7788369011',
    proNumber: "T542",
    type: 'rmt',
    lat: '43.664784',
    long: '-79.415971',
    distance: 50,
  },
  "cherry@mamamobile.com": {
    id: "cherry@mamamobile.com",
    email: "cherry@mamamobile.com",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1580627075/welcome/_K3A9907_1_eliq5n.jpg",
    firstName: "Cherry",
    lastName: "Xu",
    status: "active",
    stripe: "acct_1Gqkc1BTaYk95Ny0",
    tel: '7788369011',
    proNumber: "T542",
    type: 'admin',
    lat: '43.664784',
    long: '-79.415971',
    distance: 50,
  },
  "sarah@mamamobile.com": {
    id: "sarah@mamamobile.com",
    email: "sarah@mamamobile.com",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1580627075/welcome/_K3A9865_1_zccqz7.jpg",
    firstName: "Sarah",
    lastName: "Rennick",
    stripe: "acct_1Gqkc1BTaYk95Ny0",
    tel: '6132463688',
    proNumber: "T409",
    type: 'admin',
    lat: '43.664784',
    long: '-79.415971',
    distance: 50,
    status: "active",
  },
  "bobo12152@hotmail.com": {
    id: "bobo12152@hotmail.com",
    firstName: "Xiaonan (Shannyn)",
    lastName: "Cui",
    email: "bobo12152@hotmail.com",
    tel: "6473885818",
    gender: "female",
    birthday: "1985-01-21",
    type: "rmt",
    status: "active",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/q_20/v1572370712/headshots/shannyn_horizontal_seifv8.jpg",
    bio: "My name is Shannyn, I have been a Registered Massage Therapist since 2014 specializing in a variety of techniques.  Personalized treatments are developed to provide clients with a therapeutic experience to maximize results. I apply my specialized knowledge of the human body and its relationship to overall physical and emotional health to support the body’s natural healing process.",
    lat: "44.042140",
    long: "-79.416758",
    distance: 45,
    proNumber: "T050",
    stripe: "acct_1H4wx3Fgh1UVVUzV",
    auth: "AOxwYxIVdxWUaOU7JmbP1WKX4Co1",
  },
  "susanv004@gmail.com": {
    id: "susanv004@gmail.com",
    firstName: "Susan",
    lastName: "Vasarhelyi",
    email: "susanv004@gmail.com",
    tel: "4168803416",
    gender: "female",
    birthday: "1982-07-09",
    type: "rmt",
    status: "active",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1575662061/team/IMG_3410_qwbv1p.jpg",
    bio: "Being a mom of three, I know the importance of self-care. I love not only treating new moms, and moms with multiple children, but also connecting and holding a safe space for them to express the kind of massage therapy they need during their treatments.",
    lat: "43.670301",
    long: "-79.577703",
    distance: 20,
    proNumber: "K905",
    stripe: "acct_1H7PVTEtXuV3izt0",
    auth: "2tSQzpHm5FWIddPePo2PnuTHKWE3",
  },
  "ellie.hong2@gmail.com": {
    id: "ellie.hong2@gmail.com",
    firstName: "Ellie",
    lastName: "Hong",
    email: "ellie.hong2@gmail.com",
    tel: "4379903250",
    gender: "female",
    birthday: "1990-07-05",
    type: "physio",
    startDate: '2020-07-27',
    status: "active",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1595799841/ellie.jpg",
    bio: "Ellie is a fantastic orthopedic and pelvic floor physiotherapist, who has a passion for serving women both prenatally and postnatally in their journey of managing their pelvic floor health. She is passionate about continuing education. After completing her Bachelor of Health Sciences at McMaster University and Masters of Physical Therapy at Western University, she has taken a plethora of specialized courses in pregnancy, pelvic girdle pain, and pelvic floor assessment. She also works to integrate her manual therapy and acupuncture skills to manage any orthopedic issues postnatally for moms. Ellie’s favourite part of being a physiotherapist is that it allows her to not only be creative, but also solve complex body mechanics problems, so as to directly improve the quality of life of her clients.",
    lat: "43.644120",
    long: "-79.529174",
    distance: 15,
    proNumber: "16879",
    stripe: "acct_1GPyRiKyiFwrkblH",
    auth: "kOHzajuIj0TlCaitolwNhh9jAdn2",
  },
  "janethephysio@gmail.com": {
    id: "janethephysio@gmail.com",
    firstName: "Jane",
    lastName: "Bai",
    email: "janethephysio@gmail.com",
    tel: "4388712225",
    gender: "female",
    birthday: "1988-01-18",
    type: "physio",
    startDate: '2020-07-27',
    status: "active",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1595800619/jane.jpg",
    bio: "I believe that client education, empowerment, and exercise is the key to managing pelvic health dysfunction lifelong!",
    lat: "43.649788",
    long: "-79.387944",
    distance: 10,
    proNumber: "17691",
    stripe: "acct_1H7NH0DFsiC6AAC4",
    auth: "7IZNJ1quK1WDPMvwWwbYnGQ8KoV2",
  },
  "saito044@uottawa.ca": {
    id: "saito044@uottawa.ca",
    firstName: "Sabrina",
    lastName: "Ait-Ouali",
    email: "saito044@uottawa.ca",
    tel: "6138081668",
    gender: "female",
    birthday: "1992-07-12",
    type: "physio",
    startDate: '2020-07-27',
    status: "active",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1596063453/sabrina.jpg",
    bio: "Sabrina love working with to-be and new mamas, as it is such a special time and amazing time in a woman’s life. She strongly believes in employing a caring and holistic approach to help her clients achieve their goals. She is continuously attending conferences and specialized Pelvic Health courses, and looks forward to working with her patients every day.",
    lat: "43.557020",
    long: "-79.648946",
    distance: 30,
    proNumber: "17429",
    stripe: "acct_1H5B3ZD8iH4lgb0R",
    auth: "js6bPxjdGVUFsJGVZH1oDk5HZcx1",
  },
  "tanishadouglas94@hotmail.com": {
    id: "tanishadouglas94@hotmail.com",
    firstName: "Tanisha",
    lastName: "Anntonnette Douglas",
    email: "tanishadouglas94@hotmail.com",
    tel: "4168542545",
    gender: "female",
    birthday: "1994-05-18",
    type: "rmt",
    startDate: '2020-02-17',
    status: "active",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1581798911/team/tanisha.png",
    bio: "Hello, my name is Tanisha Douglas. I am a certified and registered massage therapist with certification in acupuncture, cupping, and reiki. I am very passionate about my job and getting to the root of the pain and or stress the patient is feeling, provide goals for treatment, and monitor progress. I have worked with a variety of patient from infancy to senior, paraplegics, high risk pregnancy, pre and post natal, palliative, through the clothes, in hospitals, etc. Always looking to improve my skills and provide the best treatment I can for each client every time!",
    lat: "43.683309",
    long: "-79.733357",
    distance: 17,
    proNumber: "W469",
    stripe: "acct_1H8w4lD0wHZLDjm7",
    auth: "s3yfxvHbxVMyvk9fUfo6TSx28z22",
  },
  "katarinahodgsonrmt@gmail.com": {
    id: "katarinahodgsonrmt@gmail.com",
    firstName: "Katarina",
    lastName: "Hodgson",
    email: "katarinahodgsonrmt@gmail.com",
    tel: "6472874634",
    gender: "female",
    birthday: "1970-04-06",
    type: "rmt",
    status: "active",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1573180529/team/Image_from_iOS_1_vgnxl5.jpg",
    bio: "Hi! I’m Katarina, and the decision to become an RMT was one of the best I’ve ever made. After being fired from the last job I had (and having never been fired, it was an overwhelming and, let’s say, interesting experience.)  I recognized that the most important things for me in a job was to be able to work with people, and help them in some capacity. I started researching and Massage Therapy was the natural fit. It’s been even better than I could have imagined! Being a mobile therapist was something I knew I wanted all along, and it has given me the opportunity to meet so many awesome and amazing people. I love the challenge of being in different environments and figuring out how I’m going to give the best treatment possible in the space I’m in. The schedule I keep is one where I do my best to accommodate the client on the day/time they’re available.",
    lat: "43.858425",
    long: "-78.932323",
    distance: 18,
    proNumber: "W456",
    stripe: "acct_1Bpfb2LxgX64HOZx",
    auth: "7tNKHdUcybYW6D4MhUgvnF9enEg2",
  },
  "anjulee4@gmail.com": {
    id: "anjulee4@gmail.com",
    firstName: "Anjulee",
    lastName: "McIntosh",
    email: "anjulee4@gmail.com",
    tel: "9054391437",
    gender: "female",
    birthday: "1982-02-03",
    type: "rmt",
    status: "active",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1572379524/Faces_OM-_24_1_rqrnln.jpg",
    bio: "Having advanced training in lymph drainage and a passion for fascial-work, pre and postnatal massage quickly became my favourite specialty! I feel blessed every day to be able to help people reconnect with themselves while healing, strengthening and rejuvenating their bodies!",
    lat: "44.643618",
    long: "-63.575529",
    distance: 10,
    proNumber: "U081",
    stripe: "acct_1HH8u0GBth9a9o1I",
    auth: "jP4KQIClTqVy4opVg2m5lTpb0sg2",
  },
  "sherlamorrisrmt@gmail.com": {
    id: "sherlamorrisrmt@gmail.com",
    firstName: "Sherla",
    lastName: "Morris",
    email: "sherlamorrisrmt@gmail.com",
    tel: "4168040286",
    gender: "female",
    birthday: "1975-09-18",
    startDate: "2020-08-17",
    type: "rmt",
    status: "active",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1597797936/team/Sherla_rlposc.jpg",
    bio: "Hi, Im Sherla. Massage is my passion. I extremely enjoy working with new moms as they go through their pregnancy journey. Having massage as part of my self-care while pregnant not only allowed me to feel more comfortable with the changes happening with my body, it allowed me to be more mentally prepared and confident to welcome my baby.",
    lat: "43.660865",
    long: "-79.763415",
    distance: 10,
    proNumber: "X293",
    stripe: "acct_1HGABAJYyn0fSVwk",
    auth: "8EJB7UA0swah4a0i4Bk8qEt3Qvt1",
  },
  "francoiseo.rmt@gmail.com": {
    id: "francoiseo.rmt@gmail.com",
    firstName: "Francoise",
    lastName: "Onsiong",
    email: "francoiseo.rmt@gmail.com",
    tel: "6477188817",
    gender: "female",
    startDate: "2020-08-17",
    type: "rmt",
    status: "active",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1597798316/team/20200701_175851_ilftsb.jpg",
    bio: "I am Francoise Onsiong, RMT, in good standing with CMTO.  I have been practising since 2017.",
    lat: "43.627921",
    long: "-79.478535",
    distance: 13,
    proNumber: "V794",
    stripe: "acct_1HELmcAHIltbp6Y4",
    auth: "trIHfUvDWrN84O4wLWwsJKALJAD3",
  },
  "sandra_pasquali@hotmail.com": {
    id: "sandra_pasquali@hotmail.com",
    firstName: "Sandra",
    lastName: "Pasquali",
    email: "sandra_pasquali@hotmail.com",
    tel: "6475672637",
    gender: "female",
    birthday: "1975-05-23",
    startDate: "2020-08-17",
    type: "rmt",
    status: "active",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1597798601/team/IMG_1271_nrgucm.jpg",
    bio: "I'm dedicated to the individual, unique and changing needs of each mama. I believe in personalizing every treatment in order to complement your overall wellness goals. During my time at Sutherland-Chan, I worked with patients at Sunnybrook Hospital’s high-risk pregnancy unit as well as the school’s own prenatal clinic. After graduation, I decided to build my practice working primarily with women’s health issues - focussed on pre/postnatal work, including scar therapy. I'm also a mom, so I understand the beautiful, and sometimes overwhelming journey :)",
    lat: "43.683318",
    long: "-79.289748",
    distance: 7,
    proNumber: "Y461",
    stripe: "acct_1HDLmxHJhScSkNHV",
    auth: "KGFiYQlWgvfoiPR4DeEdUBt3w9Y2",
  },
  "harneet.kukreja@gmail.com":   {
    id: "harneet.kukreja@gmail.com",
    firstName: "Harneet (Nithi)",
    lastName: "Kukreja",
    email: "harneet.kukreja@gmail.com",
    tel: "6472072045",
    gender: "female",
    birthday: "1990-06-17",
    startDate: "2020-09-07",
    type: "rmt",
    status: "active",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1599260352/IMG_3296_gkjbjj.jpg",
    bio: "As a graduate of Sutherland-Chan, I have extensive training on a range of conditions and therapeutic techniques. My passion lies in pregnancy and prenatal massage and in women's health overall. I aim to offer my clients a space to relax and unwind and a calming, therapeutic touch to help release tensions and make your pregnancy more enjoyable.",
    lat: "43.680251",
    long: "-79.409394",
    distance: 8,
    proNumber: "Y521",
    stripe: "acct_1HNRLvB5cs7dY6Yg",
    auth: "1byUBERMLrd7hvA2x2zBBZR4kA72",
  },
  "meg0585@gmail.com": {
    id: "meg0585@gmail.com",
    firstName: "Meghan",
    lastName: "Brookfield",
    email: "meg0585@gmail.com",
    tel: "6473898938",
    gender: "female",
    birthday: "1985-05-30",
    startDate: "2020-11-01",
    type: "rmt",
    status: "active",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1603485052/IMG_3667_n6nujb.jpg",
    bio: "Coming soon...",
    lat: "43.680424",
    long: "-79.297922",
    distance: 10,
    proNumber: "Y509",
    stripe: "acct_1HaUShIsWbMu90Vf",
    auth: "3O7sIMG97gcvLewBAXyDJPpb8S62",
  },
  "syoungrmt@gmail.com": {
    id: "syoungrmt@gmail.com",
    email: "syoungrmt@gmail.com",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1610679813/RMT_image_-_Shannon_Young_-_Copy_rczjzs.jpg",
    firstName: "Shannon",
    lastName: "Young",
    name: "Shannon Young",
    status: "active",
    lat: "44.055894",
    long: "-79.713398",
    distance: 20,
    bio: "Hello my name is Shannon. I love being a Registered Massage Therapist, working one-on-one with people and helping them feel better. I have experience in working in the high risk pregnancy clinic at Sunny Brook Hospital, and I love to help people feel their best every day.",
    rmtNumber: "Y412",
    funFact: "Outside of massage, Zoe enjoys keeping herself busy and healthy by doing yoga, taking walks, and exploring new places (near and far!). As healthy as she strives to be, she is a big foodie and enjoys talking about food as much as eating it!",
    tel: "6478210433",
    type: "RMT",
    auth: "eU3LQH1HofTLrKQjpj4kC2g0H393",
  },
  "lilycspengemann8@icloud.com": {
    id: "lilycspengemann8@icloud.com",
    email: "lilycspengemann8@icloud.com",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1618547729/lily_od5f5o.jpg",
    firstName: "Lily",
    lastName: "Spengemann",
    name: "Lily Spengemann",
    status: "active",
    lat: "43.32534810190823",
    long: "-79.79995975981733",
    distance: 20,
    bio: "After graduating with Bachelor of Business Administration from Lakehead University and well on her way to becoming a Certified General Accountant, Lily came to the realization that health and wellness was her passion. She graduated top of her class from Kikkawa College in Toronto in 1997 as a Massage Therapist. She specializes in deep tissue massage, sports and motor vehicle accident injuries  and lymphatic drainage. She also developed a passion for labour support for mothers in the early stages of delivery and postpartum. Lily has collaborated with Naturopathic Doctors on numerous client care programs and accumulated knowledge in the area nutrition, and the link between emotional trauma, physical illness and energetic blocks in the healing process. This  understanding led her to become a Reiki Master. Lily offers Reiki treatments and also teaches all levels of Reiki.",
    rmtNumber: "E052",
    tel: "4169026648",
    type: "RMT",
    auth: "FOUYALSfieVjhLvcm5LFCCtX7Vd2",
  },
  "albenne9@gmail.com": {
    id: "albenne9@gmail.com",
    email: "albenne9@gmail.com",
    firstName: "Amanda",
    lastName: "Donohue",
    name: "Amanda Donohue",
    status: "active",
    proNumber: "W963",
    tel: "6132813797",
    type: "physio",
    stripe: 'acct_1JngMPAqcyGTh60b',
    auth: "rKkA1HRodjaUeUSgPMKezsFUROb2",
  },
  "mo.martina@gmail.com": {
    id: "mo.martina@gmail.com",
    email: "mo.martina@gmail.com",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1636037310/Screen_Shot_2021-11-04_at_10.47.12_AM_fe2fwy.png",
    firstName: "Olga Martyna",
    lastName: "Modzelewska",
    name: "Olga Martyna Modzelewska",
    status: "active",
    lat: "43.810534494162376",
    long: "-79.96516282901473",
    distance: 20,
    proNumber: "Q231",
    bio: "Coming soon...",
    funFact: "Coming soon...",
    tel: "6043151429",
    type: "rmt",
    stripe: 'acct_1Jn67uBGTVd8QozQ',
    auth: "cSzwCehBJBfQBMwjIbWyKO2bOo52",
  },
  'carol.yy.gu@gmail.com': {
    id: "carol.yy.gu@gmail.com",
    email: "carol.yy.gu@gmail.com",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1636037909/mmexport1633225362017_znm16n.jpg",
    firstName: "Yuanyuan (Carol)",
    lastName: "Gu",
    name: "Yuanyuan (Carol) Gu",
    status: "active",
    lat: "43.91809236078427",
    long: "-78.92950447329018",
    distance: 16,
    proNumber: "Z028",
    bio: "Coming soon...",
    funFact: "Coming soon...",
    tel: "4162625218",
    type: "rmt",
    stripe: 'acct_1Jk7fyGRkzOVjrEp',
    auth: "lVhLrkDxvghCYv2B8u4w0T0niLO2",
  },
  "sn81@live.ca": {
    id: "sn81@live.ca",
    email: "sn81@live.ca",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1637773963/sobana_czndby.jpg",
    firstName: "Sobana",
    lastName: "Thambiah",
    name: "Sobana Thambiah",
    status: "active",
    lat: "43.733264895976845",
    long: "-79.76069813095464",
    distance: 10,
    proNumber: "B1418",
    bio: "Coming soon...",
    funFact: "Coming soon...",
    tel: "6478664535",
    type: "rmt",
    stripe: 'acct_1JwTpJGPy2yMCQgq',
    auth: "lRwBbXIg6vRl0vimLNmFKtA46Ay2",
  },
  "zc05pf@gmail.com": {
    id: "zc05pf@gmail.com",
    email: "zc05pf@gmail.com",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1637773933/photo_q6ge76.jpg",
    firstName: "Zhuoya (Linda)",
    lastName: "Chen",
    name: "Zhuoya (Linda) Chen",
    status: "active",
    lat: "43.837149772483265",
    long: "-79.28355523096424",
    distance: 10,
    proNumber: "B1041",
    bio: "Coming soon...",
    funFact: "Coming soon...",
    tel: "6479940985",
    type: "rmt",
    stripe: 'acct_1Jsd6QBoPf7iqgpw',
    auth: "FzD2U5F0zDcQgnr5811JPoptWQR2",
  },
  "upkar.brah@gmail.com": {
    id: "upkar.brah@gmail.com",
    email: "upkar.brah@gmail.com",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1637773933/16364009767275370651649749190429_mgjjzg.jpg",
    firstName: "Upkar",
    lastName: "Brah",
    name: "Upkar Brah",
    status: "active",
    lat: "43.657517348475196",
    long: "-79.75931877329907",
    distance: 15,
    proNumber: "B1359",
    bio: "Coming soon...",
    funFact: "Coming soon...",
    tel: "6472961111",
    type: "rmt",
    stripe: 'acct_1JsjZHIuxP67eglG',
    auth: "OBI8fQlGV3UG1pQeVgO4xUgl0JO2",
  },
  "theendormt@gmail.com": {
    id: "theendormt@gmail.com",
    email: "theendormt@gmail.com",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1638906431/IMG_1998_offmm0.jpg",
    firstName: "Cassandra",
    lastName: "Saez",
    name: "Cassandra Saez",
    status: "active",
    lat: "43.87507979143638",
    long: "-79.4280595021273",
    distance: 15,
    proNumber: "B1306",
    bio: "Coming soon...",
    funFact: "Coming soon...",
    tel: "6474054501",
    type: "rmt",
    stripe: 'acct_1K0BLTFykCpSFXIT',
    auth: "RKbWCdlXPlUF7ncWB60zq8TGYhf2",
  },
  "dalikapor@yahoo.com": {
    id: "dalikapor@yahoo.com",
    email: "dalikapor@yahoo.com",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1640026154/C6DA7529-4317-48E6-BF36-ECFA3357F63B_fwerpm.jpg",
    firstName: "Daliborka",
    lastName: "Kapor",
    name: "Daliborka Kapor",
    status: "active",
    lat: "43.83057029358669",
    long: "-79.64964227319223",
    distance: 20,
    proNumber: "B1480",
    bio: "Coming soon...",
    funFact: "Coming soon...",
    tel: "6477710203",
    type: "rmt",
    stripe: 'acct_1K2orvKkB42hRsfa',
    auth: "f5M5NuDJsne4izm5OtEX0PxY0i92",
  },
  "franckorinirmt@gmail.com": {
    id: "franckorinirmt@gmail.com",
    email: "franckorinirmt@gmail.com",
    avatar: "https://res.cloudinary.com/dhze7gimq/image/upload/v1660684210/HIgh_resolution_picture_qm1xc3.jpg",
    firstName: "Franc",
    lastName: "Korini",
    name: "Franc Korini",
    status: "active",
    lat: "43.695249409069504",
    long: "-79.55110303281852",
    distance: 20,
    proNumber: "B1480",
    bio: "Coming soon...",
    funFact: "Coming soon...",
    tel: "6478910702",
    type: "rmt",
    stripe: 'acct_1LXQloHQLJGuCLH9',
    auth: "JChBTMwkaMa6fjsmbVaVqraDUzT2",
  },
}

export default proMap;