import React from 'react';
import { timeMapDisplay } from '../../util/timeMap';
import BookingSlot from './BookingSlot';

const TimeSlotAll = ({ schedule, slot, modal }) => {
  const { setBookingInfoModal } = modal;

  console.log(slot);

  const findEnd = (status) => {
    let end = slot.time;
    while (schedule[end] && schedule[end].status === status ) {
      end++;
    }

    return timeMapDisplay[end];
  };

  const renderSchedule = () => {
    if (!slot) {
      return (
        <div 
          className='events disabled all'
        />
      )
    } else {
      const prevSlot = schedule[slot.time - 1] ? schedule[slot.time - 1] : {};

      if (slot.status === 'AVAILABLE') {
        if (prevSlot.status !== 'AVAILABLE') {
          const end = findEnd('AVAILABLE');
          return (
            <div 
              className='events available all'
            >
              {`${timeMapDisplay[slot.time].split(' ')[0]} - ${end}`}
            </div>
          )
        }
        return (
          <div 
            className='events available all'
          />
        )
      } else if (slot.status === 'BOOKED' || slot.status === 'BOOKED_U') {
        return <BookingSlot all bookingId={slot.booking.id} setBookingInfoModal={setBookingInfoModal} time={slot.time} />
      } else if (slot.status === 'UNAVAILABLE') {
        if (prevSlot.status === 'UNAVAILABLE') {
          return <div className='events unavailable all' />  
        } 
        return (
          <div 
            className='events unavailable all'
          >
            <p>unavailable</p>
          </div>
        )
      }

      return (
        <div
          className='events booked all'
          id={slot.id} 
          onClick={() => setBookingInfoModal(slot.booking.id)} 
        />
      )
    }
  };
  
  if (!schedule) {
    return 'loading...';
  }
  return (
    renderSchedule()
  );
};

export default TimeSlotAll;
