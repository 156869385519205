import { gql } from "apollo-boost";

const GET_SCHEDULE_DATE_ALL = gql`
  query ScheduleDateAll(
    $date: String
  ) {
    scheduleDateAll(
      date: $date
    ) {
      id
      date
      time
      status
      booking {
        id
      }
    }
  }
`;

export default GET_SCHEDULE_DATE_ALL;