import { gql } from 'apollo-boost';

const ARRIVE_BOOKING = gql`
  mutation ArriveBooking(
    $id: ID
  ) {
    arriveBooking(
      id: $id
    ) {
      success
      message
    }
  }
`;

export default ARRIVE_BOOKING;