import React, { useState, useContext, useEffect } from 'react';
import ADD_CHART from '../../graphql/mutation/addChart';
import { useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import Loading from '../../components/Loading/Loading';
import Error from '../../components/Error/Error';
import { AuthContext } from '../../context';
import { Button } from '@material-ui/core';
import FollowUp from './FollowUp';
import PhysioFollowUp from './PhysioFollowUp';
import RmtFirstVisitFollowUp from './RmtFirstVisitFollowUp';

const FOLLOWUP = [
  'three days',
  'one week',
  'two weeks', 
  'three weeks',
  'one month',
];

const FOLLOWUPDATE = {
  'three days': 3,
  'one week': 7,
  'two weeks': 14,
  'three weeks': 21,
  'one month': 30,
};

const Chart = ({ user, booking, closeModal, services }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [addChart, { data }] = useMutation(ADD_CHART);
  const [followUpTime, setFollowUpTime] = useState('');
  const [notes, setNotes] = useState('');
  const [followUpNotes, setFollowUpNotes] = useState('');
  const [focusArea, setFocusArea] = useState('');
  const [recommendations, setRecommendations] = useState('');
  const Auth = useContext(AuthContext);
  const [chart, setChart] = useState('');

  useEffect(() => {
    for (let i = 0; i < services.length; i++) {
      const service = services[i];
      const serviceIdArr = service.id.split('_');
      if (serviceIdArr[0] === 'physio') {
        setChart('physio');
      } else {
        if (serviceIdArr[2] === '70' || serviceIdArr[2] === '100') {
          setChart('rmt_first');
        } else {
          setChart('rmt_followup');
        }
      }
    }
  }, []);

  const resetState = () => {
    setFollowUpNotes('');
    setNotes('');
    setFollowUpTime('');
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      setError(false);

      const followUpDate = moment().add(FOLLOWUPDATE[followUpTime], 'days');

      const variables = {
        proId: booking.pro.id,
        bookingId: booking.id,
        userId: user.id,
        userFirstName: user.firstName,
        proFirstName: booking.pro.firstName,
        email: user.email,
        notes,
        followUpNotes,
        followUpDate: followUpDate.format().split('T')[0],
        followUpTime,
        followUpType: chart,
        recommendations,
        focusArea,
      };

      console.log(variables);

      const res = await addChart({
        variables,
      });

      setLoading(false);
      if (res.data.addChart.success) {
        alert('Chart added and follow-up email sent!');
        closeModal();
      } else {
        console.log(res)
        setError(res.data.addChart.message);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setError(err);
    }
  }

  return (
    <form className='modal-form padding-bottom-20' onSubmit={(e) => handleSubmit(e)}>
      {loading && <Loading />}
      <h5>{`Charting Notes`}</h5>
      <label>{`Charting notes for ${user.firstName}'s visit:`}</label>
      <textarea
        name="notes" 
        required
        rows="10" 
        value={notes}
        onChange={e => {setNotes(e.target.value)}}
      />
      <h5>Follow-up Email:</h5>
      {chart === 'rmt_followup' && <FollowUp 
        user={user}
        FOLLOWUP={FOLLOWUP}
        followUpTime={followUpTime} 
        setFollowUpTime={setFollowUpTime} 
        followUpNotes={followUpNotes} 
        setFollowUpNotes={setFollowUpNotes}
        pro={booking.pro}
      />}
      {
        chart === 'physio' && 
        <PhysioFollowUp 
          user={user}
          followUpNotes={followUpNotes} 
          setFollowUpNotes={setFollowUpNotes}
        />
      }
      {
        chart === 'rmt_first' && 
        <RmtFirstVisitFollowUp
          user={user}
          pro={booking.pro}
          FOLLOWUP={FOLLOWUP} 
          followUpTime={followUpTime} 
          setFollowUpTime={setFollowUpTime} 
          followUpNotes={followUpNotes} 
          setFollowUpNotes={setFollowUpNotes}
          recommendations={recommendations}
          setRecommendations={setRecommendations}
          focusArea={focusArea}
          setFocusArea={setFocusArea}
        />
      }
      {error && <Error  error={error} />}
      <Button type="submit" color="secondary" variant="contained">Add Chart</Button>
    </form>
  )
}

export default Chart;