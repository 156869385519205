import { gql } from 'apollo-boost';

const MOVE_BOOKING = gql`
  mutation MoveBooking(
    $id: ID
    $date: String
    $time: Int
    $pro: String
  ) {
    moveBooking(
      id: $id
      date: $date
      time: $time
      pro: $pro
    ) {
      success
      message
      schedule {
        id
        date
        time
        status
        booking {
          id
        }
      }
    }
  }
`;

export default MOVE_BOOKING;