import React from 'react';
import { timeMapDisplay, timeMap } from '../../util/timeMap';
import TimeSlot from './TimeSlot';
import './scheduleDisplay.css';
import moment from 'moment';

const ScheduleDisplayMobile = ({ date, schedule, modal, shift }) => {
  const day = moment(date);
  const today = moment();

  return (
    <div id='schedule-display' className='card'>
      <div className='flex justify-end'>
        <div className={today.isSame(day, 'day') ? 'schedule-display-header active flex col center' : 'schedule-display-header flex col center'}>
          <div>{day.format('ddd')}</div>
          <div className='header-day'>{day.format('D')}</div>
        </div>
      </div>
      <div className='schedule-display-body'>
        {timeMap.map((time, timeIdx) => {
          return (
            <div className='flex' key={time}>
              <div className='schedule-display-header-time'>
                {timeIdx % 2 === 0 ? timeMapDisplay[timeIdx] : null}
              </div>
              <TimeSlot shift={shift} modal={modal} day={day} time={timeIdx} proSchedule={schedule} />
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default ScheduleDisplayMobile;
