import React from 'react';
import { Icon } from '@material-ui/core';


const IntakeItem = ({ lable, value }) => {
  const renderValue = () => {
    if (value === false) {
      return <Icon color='primary'>cancel</Icon>
    } else if (value === true) {
      return <Icon color='primary'>check_circle</Icon>
    } else {
      return <p>{value}</p>
    }
  }

  return (
    <div className='intake-item'>
      <div className='intake-lable'>{lable}</div>
      <div className='intake-value'>
        {renderValue()}
      </div>
    </div>
  )
};

export default IntakeItem;