import React from 'react';

const PhysioFollowUp = ({ user, followUpNotes, setFollowUpNotes }) => (
  <>
    <label>Follow-up email</label>
    <textarea
      required
      name="follow-up-notes" 
      placeholder={`Notes here will be emailed to ${user.firstName}!`}
      rows="14" 
      value={followUpNotes}
      onChange={e => setFollowUpNotes(e.target.value)}
    />
  </>
);

export default PhysioFollowUp;