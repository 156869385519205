import React from 'react';
import UserSearch from '../UserSearch/UserSearch';
import UserCardBasic from '../UserCard/UserCardBasic';
import ServicesSelector from '../Selector/ServicesSelector';

const UserServicesCard = ({ user, setUser, services, setServices }) => {
  return (
    <div className='margin-top-20'>
      <label>Select an User</label>
      <UserSearch setUser={setUser} />
      {user && <UserCardBasic id={user.id} />}
      <label className='margin-top-20'>{user ? `Select services for ${user.firstName}` : `Select Services`}</label>
      <ServicesSelector services={services} setServices={setServices} />
    </div>
  )
}

export default UserServicesCard;