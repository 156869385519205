import React, { useState } from 'react';
import { Icon, List, ListItem } from '@material-ui/core';
import NewUserModal from '../Modal/NewUserModal';
import algolia from '../../algolia';

// const resultRenderer = ({ name }) => <Label key={name} content={name} />
// const initialState = { isLoading: false, results: [], value: '' }


const UserSearch = ({ setUser }) => {
  const [search, setSearch] = useState('');
  const [results, setResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [newUserModal, setNewUserModal] = useState(false);

  const handleChange = async (e) => {
    setSearch(e.target.value);
    const { hits } = await algolia.search(e.target.value);
    setResults(hits);
    setShowResults(true);
  };

  const handleSelectUser = (user) => {
    setSearch(`${user.firstName} ${user.lastName}`);
    setShowResults(false);
    setUser(user)
  }
  
  // handleResultSelect = (e, { result }) => {
  //   this.setState({value: result.name});
  //   this.props.selectMama(result);
  // };

  // handleSearchChange = (e, { value }) => {
  //   this.setState({ isLoading: true, value })
  //   index.search(value, (err, { hits } = {}) => {
  //     this.setState({
  //       isLoading: false,
  //       results: hits,
  //     })
  //   });
  // }

  return (
    <div>
      <div className='search'>
        {newUserModal && <NewUserModal setNewUserModal={setNewUserModal} open={newUserModal} />}
        <input
          className='search-input'
          value={search}
          onChange={handleChange}
        />
        <Icon>search</Icon>
      </div>
      {showResults && <div className='elevation-1'>
        <List>
          {results.map((hit, idx) => {
            return <ListItem button onClick={() => handleSelectUser(hit)} key={idx}>{`${hit.firstName} ${hit.lastName}`}</ListItem>
          })}
          <ListItem onClick={() => setNewUserModal(true)} button>User does not exist</ListItem>
        </List>
      </div>}
    </div>
  )
};

export default UserSearch;