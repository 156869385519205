import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Drawer, Icon, Card, Button } from '@material-ui/core';
import { timeMap, timeMapDisplay } from '../../util/timeMap';
import Loading from '../Loading/Loading';
import Error from '../Error/Error';
import moment from 'moment';
import ADD_SHIFT from '../../graphql/mutation/addShift';
import GET_SCHEDULE_DATE_BY_PRO from '../../graphql/query/scheduleDateByPro';
import { getWeek } from '../../util/getWeek';


const ShiftModal = ({ setShiftModal, id }) => {
  const today = moment().format().split('T')[0];
  const [pro, setPro] = useState('');
  const [date, setDate] = useState(today);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(1);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const week = getWeek(date);
  const [addShift, addShiftRes] = useMutation(ADD_SHIFT, 
    {
      update(cache, { data: { addShift } }) {
        const { scheduleDateByPro } = cache.readQuery({
          query: GET_SCHEDULE_DATE_BY_PRO,
          variables: {
            id: pro,
            start: week[0],
            end: week[1],
          },
        });
        cache.writeQuery({
          query: GET_SCHEDULE_DATE_BY_PRO,
          variables: {
            id: pro,
            start: week[0],
            end: week[1],
          },
          data: { 
            scheduleDateByPro: [...scheduleDateByPro, ...addShift.schedules], 
          },
        });
        setLoading(false);
        setShiftModal(false);
      }
    }
  );

  useEffect(() => {
    if (id) {
      setOpen(true);
      const idArr = id.split('-');
      const pro = idArr[0];
      const date = `${idArr[1]}-${idArr[2]}-${idArr[3]}`;
      const start = Number(idArr[4]);
      setPro(pro);
      setDate(date);
      setStartTime(start);
      setEndTime(start+1);
    } else {
      setOpen(false);
    }
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);

    try {
      const shift = {
        date,
        startTime: Number(startTime),
        endTime: Number(endTime),
        proId: pro,
        status: 'AVAILABLE',
      };
  
      await addShift({
        variables: shift,
      });
    } catch (err) {
      setError('error', err);
    }
  }

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={open}
    >
      {loading && <Loading />}
      <div className='modal-header'>
        <h3>New Shift</h3>
        <Icon className='clickable' onClick={() => setShiftModal(false)}>close</Icon>
      </div>
      <Card>
        <form className='padding-20' onSubmit={handleSubmit}>
          <label htmlFor='shift-start'>Date</label>
          <input required type="date" value={date} min={today} onChange={e => setDate(e.target.value)} />
          <label htmlFor='shift-start'>Start At</label>
          <select
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            required
          >
            {timeMap.map(( time, idx ) => (
              <option key={idx} value={idx}>{time}</option>
            ))}
          </select>
          <label htmlFor='shift-end'>End At</label>
          <select
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            required
          >
            {timeMap.map(( time, idx ) => idx > startTime && <option key={idx} value={idx}>{time}</option>)}
          </select>
          <Button type="submit" variant="contained" color="secondary">Save Shift</Button>
        </form>
      </Card>
    </Drawer>
  );
}

export default ShiftModal;
