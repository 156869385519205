import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import IntakeTab from './IntakeTab';
import Charts from '../Charts/Charts';
import Bookings from '../Bookings/Bookings';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%',
    margin: '20px auto',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    backgroudColor: 'var(--color-transparentTeal)',
  },
  tab: {
    width: '33%',
  }
}));

const UserTabs = ({ user }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="user tabs">
          <Tab className={classes.tab} label="Intakes" />
          <Tab className={classes.tab} label="Bookings" />
          <Tab className={classes.tab} label="Charts" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <IntakeTab user={user} intakes={user.intakes} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Bookings bookings={user.bookings} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Charts charts={user.charts} />
      </TabPanel>
    </div>
  );
}

export default UserTabs;