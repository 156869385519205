import React, { useState, useEffect } from 'react';
import { Drawer, Icon, Card, Select, CardContent, CardActionArea, Backdrop, CircularProgress } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import GET_USER from '../../graphql/query/user';
import UserTabs from '../UserTabs/UserTabs';
import LoadingBasic from '../Loading/LoadingBasic';


const UserModal = ({ id, closeModal, state }) => {
  // const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const { loading, error, data } = useQuery(GET_USER, {
    variables: { id }
  });

  useEffect(() => {
    if (data) {
      setUser(data.user);
    }
  }, [data]);

  if (!user) return <LoadingBasic />

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={state}
    >
      <div className='modal-header'>
        <h3>User Info</h3>
        <Icon className='clickable' onClick={closeModal}>close</Icon>
      </div>
      <Card className='modal-body'>
        <CardContent>
          <div className='modal-body-header'>
            <p>
              {`${user.firstName} ${user.lastName}`}
            </p>
          </div>
          <div className='item-icon'>
            <Icon>home</Icon>
            <p>
              {user.address.unit ? `${user.address.unit} - ${user.address.street}, ${user.address.city}` : `${user.address.street}, ${user.address.city}`}
            </p>
          </div>
        </CardContent>
      </Card>
      <UserTabs user={user} />
    </Drawer>
  )
}

export default UserModal;