import React, { useState, useContext, useEffect } from 'react';
import Calendar from 'react-calendar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { AuthContext } from '../../context';
import { useQuery } from '@apollo/react-hooks';
import 'react-calendar/dist/Calendar.css';
import './schedule.css';
import ProSelector from '../../components/Selector/ProSelector';
import { useHistory } from 'react-router-dom';
import GET_SCHEDULE_DATE_BY_PRO from '../../graphql/query/scheduleDateByPro';
import { getWeek } from '../../util/getWeek';
import ScheduleDisplay from '../../components/ScheduleDisplay/ScheduleDisplay';
import BookingInfoModal from '../../components/Modal/BookingInfoModal';
import ShiftModal from '../../components/Modal/ShiftModal';
import BookingModal from '../../components/Modal/BookingModal';
import LoadingBasic from '../../components/Loading/LoadingBasic';
import ScheduleDisplayMobile from '../../components/ScheduleDisplay/ScheduleDisplayMobile';

const Schedule = () => {
  const today = new Date();
  const [shift, setShift] = useState(false);
  const [date, setDate] = useState(today);
  const [showSchedule, setShowSchedule] = useState(false);
  const [schedule, setSchedule] = useState({});
  const [bookingModal, setBookingModal] = useState(false);
  const [shiftModal, setShiftModal] = useState(false);
  const [bookingInfoModal, setBookingInfoModal] = useState(false);
  const Auth = useContext(AuthContext);
  const history = useHistory();
  const week = getWeek(date);
  
  // if (!Auth.id) {
  //   history.push('/');
  // }

  if (!Auth.id || Auth.id === 'tanishadouglas94@hotmail.com') {
    history.push('/');
  }
  
  const { loading, error, data } = useQuery(GET_SCHEDULE_DATE_BY_PRO, {
    variables: {
      id: Auth.proSelected,
      start: week[0],
      end: week[1],
    }
  });
  
  useEffect(() => {
    if (data) {
      const result = {};
      data.scheduleDateByPro.forEach(slot => result[slot.id] = slot);
      setShowSchedule(true);
      setSchedule(result);
    } else {
      setShowSchedule(false);
    }
  }, [data]);

  const handleCalendarSelect = (day) => {
    const newDate = new Date(day);
    setDate(newDate);
  }

  const renderSchedule = () => {
    if (loading) {
      return <LoadingBasic />
    } else {
      if (window.innerWidth <= 600) {
        return <ScheduleDisplayMobile date={date} schedule={schedule} shift={shift} modal={{ setBookingModal, setBookingInfoModal, setShiftModal }}/>
      } 

      return <ScheduleDisplay date={date} schedule={schedule} shift={shift} modal={{ setBookingModal, setBookingInfoModal, setShiftModal }}/>
    }
  }

  const handleProSelect = (e) => {
    Auth.setState('proSelected', e.target.value);
  }

  return (
    <div id='schedule' className='flex col center'>
      <div className='flex center margin-top-20'>
        <Calendar className='calendar' value={date} onChange={(day) => handleCalendarSelect(day)} locale='en-US' />
        <div className='margin-30'>
          <FormControlLabel
            control={
              <Switch
                checked={shift}
                onChange={() => setShift(!shift)}
                color="secondary"
              />
            }
            label="Shift"
          />
          {Auth.access === 'admin' && <ProSelector handleSelect={handleProSelect} selected={Auth.proSelected} />}
        </div>
      </div>
      {bookingInfoModal && <BookingInfoModal setBookingInfoModal={setBookingInfoModal} id={bookingInfoModal} schedule={schedule} />}
      <ShiftModal setShiftModal={setShiftModal} id={shiftModal} />
      <BookingModal setBookingModal={setBookingModal} id={bookingModal} />
      {renderSchedule()}
    </div>
  )
}

export default Schedule;
