import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import './components/Modal/modal.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { InMemoryCache } from 'apollo-cache-inmemory';
import theme from './theme.js';

import { AuthContext } from './context';

// pages
import Login from './pages/Login/Login';
import Schedule from './pages/Schedule/Schedule';
import AllSchedule from './pages/Schedule/AllSchedule';
import Map from './pages/Map/Map';
import MapRadius from './pages/Map/MapRadius';

const client = new ApolloClient({
  cache: new InMemoryCache({
    dataIdFromObject: object => object.id || null,
  }),
  uri: 'https://api.mamamobile.com/graphql',
  // uri: 'http://localhost:4000/graphql'
});

const themeCustomize = createMuiTheme(theme);

const id = localStorage.getItem('id');
const firstName = localStorage.getItem('firstName');
const access = localStorage.getItem('access');
// const access = 'admin';

class App extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      id,
      firstName,
      access,
      proSelected: id,
      setState: (key, value) => {
        this.setState({ [key]: value })
      }
    };

    if (id === 'tanishadouglas94@hotmail.com') {
      localStorage.clear();
    }
  }

  render () {
    return (
      <Router>
        <ApolloProvider client={client}>
          <AuthContext.Provider value={this.state}>
            <ThemeProvider theme={themeCustomize}>
            <div>
              <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/schedule" component={Schedule} />
                <Route path='/schedule/all' component={AllSchedule} />
                <Route exact path='/map' render={() => <Map isMarkerShown />}/>
                <Route path='/map/radius' render={() => <MapRadius isMarkerShown />}/>
              </Switch>
            </div>
            </ThemeProvider>
          </AuthContext.Provider>
        </ApolloProvider>
      </Router>
    )
  }
}

export default App;
