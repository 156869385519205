import React from 'react';

const FollowUp = ({ FOLLOWUP, user, followUpTime, setFollowUpTime, followUpNotes, setFollowUpNotes, pro }) => (
  <>
    <label>Select follow-up date:</label>
    <select required name="follow-up-date" value={followUpTime} onChange={e => setFollowUpTime(e.target.value)}>
      <option value="">--Please choose an option--</option>
      {FOLLOWUP.map(item => <option key={item} value={item}>{item}</option>)}
    </select>
    <label>Follow-up notes</label>
    <textarea
      required
      name="follow-up-notes" 
      placeholder={`Follow-up notes for ${user.firstName} will be e-mailed to mama along with the recommended follow-up date`}
      rows="6" 
      value={followUpNotes}
      onChange={e => setFollowUpNotes(e.target.value)}
    />
    <div>
      <h5>{`Follow-up email to ${user.firstName}👇(please review)`}</h5>
      <p>{`Hi ${user.firstName}`}</p>
      {followUpNotes.split('\n').map((item, idx) => <p key={idx}><b>{item}</b></p>)}
      <p>{`To proceed with your treatment, I recommend you book your next treament for `}<b>{followUpTime}</b>{` from now!`}</p>
      <p>Please let me know if you have any questions or concerns.</p>
      <p>Have a great day!</p>
      <p>{pro.firstName}</p>
    </div>
  </>
);

export default FollowUp;