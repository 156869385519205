import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useMutation } from '@apollo/react-hooks';
import { Drawer, Icon, Button } from '@material-ui/core';
import Autocomplete from 'react-google-autocomplete';
import Error from '../Error/Error';
import ADD_USER from '../../graphql/mutation/addUser';
import LoadingBasic from '../Loading/LoadingBasic';

const NewUserModal = ({ setNewUserModal, open }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [tel, setTel] = useState('');
  const [unit, setUnit] = useState('');
  const [address, setAddress] = useState([]);
  const [addressInput, setAddressInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [addUser, { data }] = useMutation(ADD_USER);

  const capitalize = (str) => {
    let result = str.trim();
    result = result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
    return result;
  };

  const formatAddress = () => {
    if (!address) {
      setError('Must select an address from the dropdown!');
    } else {
      const street = [];
      const result = {
        id: uuid(),
        address: address.formatted_address,
        lat: address.geometry.location.lat().toString(),
        long: address.geometry.location.lng().toString(),
      }

      for (let i = 0; i < address.address_components.length; i++) {
        const {types, short_name} = address.address_components[i];
        if (types.includes('street_number') || types.includes('route')) {
          street.push(short_name);
        } else if (types.includes('locality')) {
          result.city = short_name;
        } else if (types.includes('administrative_area_level_1')) {
          result.province = short_name;
        } else if (types.includes('country')) {
          result.country = short_name;
        } else if (types.includes('postal_code')) {
          result.postalCode = short_name;
        }
      }
      
      result.street = street.join(' ');
      return result;
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      setError(false);
      const { id, street, city, province, country, postalCode, lat, long } = formatAddress();
      const user = {
        firstName: capitalize(firstName),
        lastName: capitalize(lastName),
        email: email.toLowerCase(),
        tel: tel,
        addressId: id,
        unit,
        street,
        city,
        province,
        country,
        postalCode,
        lat,
        long,
      };
      console.log('user', user);
  
      const newUser = await addUser({ variables: user });
      setLoading(false);
      if (newUser.data.addNewUser.success) {
        setNewUserModal(false);
      } else {
        setError(newUser.data.addNewUser.message);
      }
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={open}
    >
      {loading && <LoadingBasic />}
      <div className='modal-header'>
        <h3>New User</h3>
        <Icon className='clickable' onClick={() => setNewUserModal(false)}>close</Icon>
      </div>
      <form className='padding-20' onSubmit={handleSubmit}>
        <label>First Name</label>
        <input required type="text" value={firstName} onChange={e => setFirstName(e.target.value)} />
        <label>Last Name</label>
        <input required type="text" value={lastName} onChange={e => setLastName(e.target.value)} />
        <label>Email</label>
        <input required type="email" value={email} onChange={e => setEmail(e.target.value)} />
        <label>Phone Number</label>
        <input type="tel" value={tel} onChange={e => setTel(e.target.value)} />
        <label>Unit Number</label>
        <input type="text" value={unit} onChange={e => setUnit(e.target.value)} />
        <label>Address</label>
        <Autocomplete
          value={addressInput}
          onChange={(e) => setAddressInput(e.target.value)}
          onPlaceSelected={(address) => {
            setAddress(address);
            setAddressInput(address.formatted_address);
          }}
          required
          name='address'
          types={['address']}
          placeholder=''
          componentRestrictions={{country: "ca"}}
        />
        {error && <Error error={error} />}
        <Button type="submit" color="secondary" variant="contained">Create New User</Button>
      </form>
    </Drawer>
  );
}

export default NewUserModal;
