import React, { useState, useContext, useEffect } from 'react';
import Calendar from 'react-calendar';
import { AuthContext } from '../../context';
import { useQuery } from '@apollo/react-hooks';
import 'react-calendar/dist/Calendar.css';
import './schedule.css';
import { useHistory } from 'react-router-dom';
import { getWeek } from '../../util/getWeek';
import BookingInfoModal from '../../components/Modal/BookingInfoModal';
import LoadingBasic from '../../components/Loading/LoadingBasic';
import ScheduleDisplayAll from '../../components/ScheduleDisplay/ScheduleDisplayAll';
import GET_SCHEDULE_DATE_ALL from '../../graphql/query/scheduleDateAll';

const AllSchedule = () => {
  const today = new Date();
  const [date, setDate] = useState(today);
  const [showSchedule, setShowSchedule] = useState(false);
  const [pros, setPros] = useState([]);
  const [schedule, setSchedule] = useState({});
  const [bookingInfoModal, setBookingInfoModal] = useState(false);
  const Auth = useContext(AuthContext);
  const history = useHistory();
  
  if (!Auth.id || Auth.id === 'tanishadouglas94@hotmail.com') {
    history.push('/');
  }

  console.log(Auth)
  if (Auth.access !== 'admin') {
    history.push('/schedule');
  }
  
  const { loading, error, data } = useQuery(GET_SCHEDULE_DATE_ALL, {
    variables: {
      date: date.toISOString(),
    }
  });

  useEffect(() => {
    if (data) {
      const result = {};
      const pros = [];
      data.scheduleDateAll.forEach(item => {
        const { id, time } = item;
        const idArr = item.id.split('-');

        const proId = idArr[0];

        if (result[proId]) {
          result[proId][time] = item;
        } else {
          pros.push(proId);
          result[proId] = {
            [time]: item,
          }
        }
      });
      setShowSchedule(true);
      setSchedule(result);
      setPros(pros);
    } else {
      setShowSchedule(false);
    }
  }, [data]);

  const handleCalendarSelect = (day) => {
    const newDate = new Date(day);
    setDate(newDate);
  }

  if (loading) {
    return <LoadingBasic />
  }
  
  return (
    <div id='schedule' className='flex col center'>
      <Calendar className='calendar' value={date} onChange={(day) => handleCalendarSelect(day)} locale='en-US' />
      {bookingInfoModal && <BookingInfoModal setBookingInfoModal={setBookingInfoModal} id={bookingInfoModal} schedule={schedule} />}
      {showSchedule && <ScheduleDisplayAll schedule={schedule} pros={pros} modal={{ bookingInfoModal, setBookingInfoModal }} />}
    </div>
  )
}

export default AllSchedule;
