import React from 'react';
import BookingItem from './BookingItem';

const Bookings = ({ bookings }) => {
  if (!bookings) {
    return 'no bookings';
  }  

  return (
    <div>
      {bookings.map(booking => <BookingItem key={booking.id} booking={booking} />)}
    </div>
  )
}

export default Bookings;