import { gql } from 'apollo-boost';

const DELETE_SHIFT = gql`
  mutation DeleteShift(
    $id: ID
  ) {
    deleteShift(
      id: $id
    ) {
      success
      message
    }
  }
`;

export default DELETE_SHIFT;