import { gql } from 'apollo-boost';

const ADD_SHIFT = gql`
  mutation AddShift(
    $date: String
    $startTime: Int
    $endTime: Int
    $status: ScheduleStatus
    $proId: ID!
  ) {
    addShift(
      date: $date
      startTime: $startTime
      endTime: $endTime
      status: $status
      proId: $proId
    ) {
      success
      message
      schedules {
        id
        date
        time
        status
        booking {
          id
        }
      }
    }
  }
`;

export default ADD_SHIFT;