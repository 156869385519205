import React from 'react';
import ChartItem from './ChartItem';

const Charts = ({ charts }) => {
  if (!charts) {
    return 'no charts';
  }  

  return (
    <div>
      {charts.map(chart => <ChartItem key={chart.id} chart={chart} />)}
    </div>
  )
}

export default Charts;