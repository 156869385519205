import gql from 'graphql-tag';

const GET_PROMOS = gql`
  {
    promos {
      id
      description
      value
    }
  }
`

export default GET_PROMOS;