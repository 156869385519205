import React, { useState } from 'react';
import moment from 'moment';
import Intake from '../Intake/Intake';

const IntakeTab = ({ intakes, user }) => {
  const [intakeSelected, setIntakeSelected] = useState('');
  
  return (
    <div>
      <label>Select an intake form:</label>
      <select value={intakeSelected} onChange={e => setIntakeSelected(e.target.value)}>
        <option value=''>Select an intake form:</option>
        {intakes.map((intake, idx) => {
          const date = moment(new Date(0));
          date.add(intake.createdAt, 'ms');

          return (
            <option key={idx} value={idx}>{`${date.format().split('T')[0]} - ${intake.complete ? 'complete' : 'incomplete'}`}</option>
          )
        })}
      </select>
      {(intakeSelected !== '') && <Intake user={user} id={intakes[intakeSelected].id} />}
    </div>
  )
}

export default IntakeTab;