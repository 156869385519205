import React, { useState } from 'react';
import { Icon } from '@material-ui/core';

const AddressComponents = ['buzzer', 'homeType', 'hasStairs', 'stairsNotes', 'parkingType', 'parkingNotes'];
const addressMap = {
  'buzzer': 'Buzzer Number',
  'homeType': 'Type of Home', 
  'hasStairs': 'Has Stairs to the Front Door',
  'stairsNotes': 'Notes About Stairs',
  'parkingType': 'Type of Parking',
  'parkingNotes': 'Notes About Parking',
}

const AddressDetails = ({ address }) => {
  const [expand, setExpand] = useState(false);

  return (
    <div>
      <div onClick={() => setExpand(!expand)} className='flex-row-space-between clickable'>
        <h5>Home Details</h5>
        {expand && <Icon>expand_less</Icon>}
        {!expand && <Icon>expand_more</Icon>}
      </div>
      { expand && 
        AddressComponents.map(item => {
          if (address[item] !== null) {
            let value = address[item];

            if (value === false) {
              value = 'no';
            } else if (value === true) {
              value = 'yes';
            }
            return (
              <div key={item} className='list-item'>
                <h5 className='item-title'>{addressMap[item]}</h5>
                <p>{value}</p>
              </div>
            )
          }
      })}
    </div>
  )
}

export default AddressDetails;