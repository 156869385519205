import { DateTime } from 'luxon';
import { timeMap24 } from './timeMap';

const isWithin24Hours = (date, timeIdx) => {
  // get time now
  const now = DateTime.local().setZone('America/Toronto');

  if (date) {
    // get booking time
    const dateArr = date.split('-');
    const time = timeMap24[timeIdx];
    const timeArr = time.split(':');
    const bookingDate = DateTime.fromObject({ 
      year: Number(dateArr[0]), 
      month: Number(dateArr[1]), 
      day: Number(dateArr[2]), 
      hour: Number(timeArr[0]),
      minute: Number(timeArr[1]),
      zone: 'America/Toronto' 
    });
  
    // get time difference
    const diff = bookingDate.diff(now, 'hours');
    const diffHour = diff.get('hours');
    if (diffHour < 24 && diffHour > 0 ) {
      return true;
    }
  }
  return false;
}

export {
  isWithin24Hours,
}

