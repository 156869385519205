import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import GET_INTAKE from '../../graphql/query/intake';
import IntakeItem from './IntakeItem';
import { Button } from '@material-ui/core';
import './intake.css';
import moment from 'moment';

const personal = [
  'id', 'userType', 'createdAt', 'updatedAt', 'emergencyContact', 'emergencyContactTel', 
  'emergencyContactRel', 'familyDoctor', 'familyDoctorTel', 'familyDoctorAddress', 'occupation',
  'insurance', 'massageFrequency', 'hadMassageBefore', 'lastMassage', 'otherTreatments', 'massageType', 'massagePressure',
  'hasNoMassageArea', 'noMassageArea', 'hasTreatmentGoal', 'treatmentGoal',

];

const medical = [
  'hasAllergies', 'allergies',
  'hasMedication', 'medication', 'hasChronicPain', 'chronicPain', 'hasSurgeryOrInjury',
  'surgeryOrInjury', 'extraNotes'
];

const prenatal = [
  'musculoSkeletal', 'respiratory', 'digestive', 'nervousSystem', 'skin', 'otherConditions', 'conditionNotes',
  'pregnancyNumber', 'birthNumber', 'trimester', 'dueDate', 'existingPrenatalConditions', 'pregnancyRisk',
];

const postnatal = [
  'musculoSkeletal', 'respiratory', 'digestive', 'nervousSystem', 'skin', 'otherConditions', 'conditionNotes',
  'birthDate', 'deliveryMethod', 'childrenNumber', 'hasBirthComplications', 'birthComplications',
  'hasPostpartumDepression', 'postpartumDepression', 'hasMidwife', 'midwife', 'midwifeTel', 'prenatalConditionNotes',
];

const baby = [
  'weeksGestation', 'otherConditions', 'conditionNotes', 'hasPostpartumComplication',
  'postpartumComplication',
];


const intakeNameMap = {
  id: 'Intake Id',
  emergencyContact: 'Emergency Contact',
  emergencyContactTel: 'Emergency Contact Tel',
  emergencyContactRel: 'Emergency Contact Relationship', 
  familyDoctor: 'Family Doctor',
  familyDoctorTel: 'Family Doctor Tel',
  familyDoctorAddress: 'Family Doctor Address',
  occupation: 'Occupation',
  insurance: 'Insurance',
  massageFrequency: 'Massage Frequency',
  userType: 'User Type',
  createdAt: 'Intake Created Date',
  updatedAt: 'Last Updated Date',
  hasMedication: 'Is Taking Medication',
  medication: 'Medication',
  hasChronicPain: 'Has Chronic Pain',
  chronicPain: 'Chronic Pain',
  hasSurgeryOrInjury: 'Had Surgery or Injury',
  surgeryOrInjury: 'Existing Surgery or Injury',
  musculoSkeletal: 'Musculo Skeletal Conditions',
  respiratory: 'Respiratory Conditions',
  digestive: 'Digestive Conditions',
  nervousSystem: 'Nervous System Conditions',
  skin: 'Skin Conditions',
  otherConditions: 'Other Conditions',
  conditionNotes: 'Other Condition Notes', 
  hadMassageBefore: 'Has Had Massage Before',
  lastMassage: 'Date of Last Massage',
  otherTreatments: 'Other Treatments',
  massageType: 'Type of Massage Requested',
  massagePressure:  'Massage Pressure',
  hasAllergies: 'Has Allergies',
  allergies: 'Allergies', 
  hasNoMassageArea:  'Has Areas To Avoid During Massage',
  noMassageArea: 'Areas To Avoid',
  hasTreatmentGoal: 'Has Treatment Goals',
  treatmentGoal: 'Treatment Goals', 
  extraNotes: 'Extra Notes',

  // # prenatal 
  pregnancyNumber: 'Number of Pregnancy',
  birthNumber: 'Number of Birth',
  trimester: 'Trimester',
  dueDate: 'Due Date',
  existingPrenatalConditions: 'Prenatal Conditions',
  pregnancyRisk: 'Pregnancy Risk Level',
  hasMidwife: 'Has Pregnancy Carer Other Than Doctor',
  midwife: 'Pregnancy Carer Name',
  midwifeTel: 'Pregnancy Carer Phone',
  prenatalConditionNotes: 'Prenatal Condition Notes',

  // postnatal & busy mama
  birthDate: 'Date of Last Birth',
  deliveryMethod: 'Delivery Method',
  childrenNumber: 'Number of Children',
  hasBirthComplications: 'Has Any Birth Complications',
  birthComplications: 'Birth Complications',
  hasPostpartumDepression: 'Has Postpartum Depression',
  postpartumDepression: 'Postpartum Depression',

  // baby 
  weeksGestation: 'Weeks Gestation',
  hasPostpartumComplication: 'Has Postpartum Complications',
  postpartumComplication: 'Postpartum Complications',
  
}

const Intake = ({ id, user }) => {
  const [intake, setIntake] = useState(null);
  const { loading, error, data } = useQuery(GET_INTAKE, {
    variables: { id }
  });

  useEffect(() => {
    if (data) {
      setIntake(data.intake);
    }
  }, [data, id]);

  const onResendIntake = () => {
    try {
      const intakeUrl = `https://us-central1-mama-mobile-238622.cloudfunctions.net/api/email/intakeForm`;
      const intakeUrlTest = 'http://localhost:5000/mama-mobile-238622/us-central1/api/email/intakeForm';
      const data = {
        firstName: user.firstName,
        email: user.email,
        intakeId: id,
      };
    
      const fetchData = { 
        method: 'POST', 
        body: JSON.stringify(data),
      };
    
      fetch(intakeUrl, fetchData);
    } catch (err) {
      console.log(err);
    }
  }

  // const renderExtraInfo = () => {
  //   if (intake.userType === 'prenatal') {
  //     return (
  //       <h5 className='intake-title'>Prenatal</h5>
  //       {prenatal.map(key => 
  //         (intake[key] !== null) && <IntakeItem key={key} lable={intakeNameMap[key]} value={intake[key]} />
  //       )}
  //     )
  //   }
  // }

  if (!intake) return 'loading...';
  console.log(intake);

  return (
    <div>
      <div className='flex-row-end'>
        <Button onClick={onResendIntake} variant="outlined" color="primary">Resend Intake</Button>
      </div>
      <h5 className='intake-title'>Personal Information</h5>
      {personal.map(key => {
        if (key === 'createdAt') {
          const date = moment(new Date(0));
          date.add(intake.createdAt, 'ms');
          return <IntakeItem key={key} lable={intakeNameMap[key]} value={date.format().split('T')[0]} />
        }

        if (key === 'updatedAt') {
          const date = moment(new Date(0));
          date.add(intake.updatedAt, 'ms');

          return <IntakeItem key={key} lable={intakeNameMap[key]} value={date.format().split('T')[0]} />
        }
        if (intake[key] !== null) {
          return <IntakeItem key={key} lable={intakeNameMap[key]} value={intake[key]} />
        }
      })}
      <h5 className='intake-title'>Medical Information</h5>
      {medical.map(key => 
        (intake[key] !== null) && <IntakeItem key={key} lable={intakeNameMap[key]} value={intake[key]} />
      )}
      {intake.userType === 'prenatal' && <h5 className='intake-title'>Prenatal</h5>}
      {intake.userType === 'postnatal' && <h5 className='intake-title'>Postnatal</h5>}
      {intake.userType === 'busymama' && <h5 className='intake-title'>Busy Mama</h5>}
      {intake.userType === 'baby' && <h5 className='intake-title'>Infant</h5>}
      {intake.userType === 'prenatal' && 
        prenatal.map(key => 
          (intake[key] !== null) && <IntakeItem key={key} lable={intakeNameMap[key]} value={intake[key]} />
        )
      }
      {intake.userType === 'postnatal' && 
        postnatal.map(key => 
          (intake[key] !== null) && <IntakeItem key={key} lable={intakeNameMap[key]} value={intake[key]} />
        )
      }
      {intake.userType === 'baby' && 
        baby.map(key => 
          (intake[key] !== null) && <IntakeItem key={key} lable={intakeNameMap[key]} value={intake[key]} />
        )
      }
      {intake.userType === 'busymama' && 
        postnatal.map(key => 
          (intake[key] !== null) && <IntakeItem key={key} lable={intakeNameMap[key]} value={intake[key]} />
        )
      }
    </div>
  )
}

export default Intake;