import { gql } from "apollo-boost";

const GET_PROXY = gql`
  mutation Proxy(
    $id: ID!
  ) {
    proxy(id: $id) {
      success
      message
    }
  }
`;

export default GET_PROXY;