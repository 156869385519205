import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Select, Input, Chip, MenuItem } from '@material-ui/core';
import GET_SERVICES from '../../graphql/query/services';

const ServicesSelector = ({ services, setServices }) => {
  const { loading, error, data } = useQuery(GET_SERVICES);

  if (loading) return 'loading...';

  return (
    <Select
      multiple
      value={services}
      onChange={(e) => setServices(e.target.value)}
      input={<Input disableUnderline id="select-services" />}
      renderValue={services => (
        <div>
          {services.map(service => (
            <Chip key={service.id} label={service.id} />
          ))}
        </div>
      )}
    >
      {data.services.map((service, idx) => (
        <MenuItem key={service.id} value={service}>
          {`${service.name} - ${service.duration} min`}
        </MenuItem>
      ))}
    </Select>
  )
}

export default ServicesSelector;