import React from 'react';
import GET_PROS from '../../graphql/query/pros';
import { useQuery } from '@apollo/react-hooks';


const ProSelector = ({ handleSelect, selected }) => {
  const { loading, error, data } = useQuery(GET_PROS);

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <div className='margin-top-20'>
      <label>SELECT A PRO:</label>
      <select
        value={selected}
        onChange={handleSelect}
      >
        <option default value=''>Please select a Pro</option>
        {data.pros.map(pro => (
          <option key={pro.id} value={pro.id}>{`${pro.firstName} ${pro.lastName}`}</option>
        ))}
      </select>
    </div>
  );
};

export default ProSelector;
