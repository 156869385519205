import React from 'react';
import { timeMapDisplay, timeMap } from '../../util/timeMap';
import TimeSlot from './TimeSlot';
import './scheduleDisplay.css';
import moment from 'moment';

const ScheduleDisplay = ({ date, schedule, modal, shift }) => {
  const today = moment();
  const week =  [
    moment(date).day(0),
    moment(date).day(1),
    moment(date).day(2),
    moment(date).day(3),
    moment(date).day(4),
    moment(date).day(5),
    moment(date).day(6),
  ];

  return (
    <div id='schedule-display' className='card'>
      <div className='flex justify-end'>
        {week.map((day, idx) => (
          <div className={today.isSame(day, 'day') ? 'schedule-display-header active flex col center' : 'schedule-display-header flex col center'} key={idx}>
            <div>{day.format('ddd')}</div>
            <div className='header-day'>{day.format('D')}</div>
          </div>
        ))}
      </div>
      <div className='schedule-display-body'>
        {timeMap.map((time, timeIdx) => {
          return (
            <div className='flex' key={time}>
              <div className='schedule-display-header-time'>
                  {timeIdx % 2 === 0 ? timeMapDisplay[timeIdx] : null}
              </div>
              {week.map((day, idx) => (
                <TimeSlot shift={shift} modal={modal} key={idx} day={day} time={timeIdx} proSchedule={schedule} />
              ))}
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default ScheduleDisplay;
