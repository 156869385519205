import React, { useEffect } from 'react';
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Circle } from "react-google-maps"
import address from '../../util/address.json';

const MyMapComponent = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBDAZmdNIn0u8q97UClMqPC4DZ--GHs-gk&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100vh` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  return (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: 43.65037580000001, lng: -79.3807063 }}
    >
      {
        address.map(item => (
          <Marker position={{ lat: parseFloat(item.lat), lng: parseFloat(item.long) }} />
        ))
      }
    </GoogleMap>
  );
})

export default MyMapComponent;