import React, { useState } from 'react';
import { Icon, Card, CardContent, Collapse, IconButton, CardActionArea } from '@material-ui/core';
import Chart from '../Chart/Chart';
import UserModal from '../Modal/UserModal';

const UserCard = ({ user, services, booking }) => {
  const [ loading, setLoading ] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [userModal, setUserModal] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className='modal-body'>
      {userModal && <UserModal id={user.id} closeModal={() => setUserModal(false)} state={userModal} />}
      <CardContent>
        <CardActionArea onClick={() => setUserModal(true)}>
          <div className='modal-body-header'  >
            <p>
              {`${user.firstName} ${user.lastName}`}
            </p>
            <div className='margin-left-5'>
              {booking.user.new && <Icon color="primary" fontSize="small">fiber_new</Icon>}
              {/* {booking.user.intakes[0].complete && <Icon color="primary" fontSize="small">assignment_turned_in_icon</Icon>} */}
              {/* {<Icon color="primary" fontSize="small">chat</Icon>} */}
            </div>
          </div>
          <h4>Services:</h4>
          {services.map(service => (
            <p key={service.id} className='modal-body-text'>
              {`${service.name} - ${service.duration} min`}
            </p>
          ))}
        </CardActionArea>
      </CardContent>
      <button
        className='full-width-button clickable'
        onClick={handleExpandClick}
        aria-expanded={expanded}
        aria-label="show more"
      >
        {`Charting for ${user.firstName}`}
        {!expanded && <Icon>expand_more</Icon>}
        {expanded && <Icon>expand_less</Icon>}
      </button>
        {expanded && 
          <Chart closeModal={() => setExpanded(false)} user={user} services={services} booking={booking} />
        }
    </Card>
  )
}

export default UserCard;