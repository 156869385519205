import React, { useState } from 'react';
import { Icon, Card, CardContent} from '@material-ui/core';
import UserModal from '../Modal/UserModal';
import { useMutation, useQuery } from '@apollo/react-hooks';
import GET_USER from '../../graphql/query/user';
import LoadingBasic from '../Loading/LoadingBasic';


const UserCardBasic = ({ id }) => {
  const [userModal, setUserModal] = useState(false);
  const { data, loading, error } = useQuery(GET_USER, {variables: { id }});

  if (loading) return <LoadingBasic />;
  if (error) return `Can't find this user...`;

  const { user } = data;
  return (
    <Card className='sub-card'>
      {userModal && <UserModal id={user.id} closeModal={() => setUserModal(false)} state={userModal} />}
      <CardContent>
        <div className='modal-body-header clickable' onClick={() => setUserModal(true)} >
          <p>
            {`${user.firstName} ${user.lastName}`}
          </p>
        </div>
        {/* <div className='item-icon'>
            <Icon>call</Icon>
            <p>{user.tel}</p>
          </div> */}
          <div className='item-icon'>
            <Icon>home</Icon>
            <p>
              {user.address ? user.address.unit ? `${user.address.unit} - ${user.address.street}, ${user.address.city}` : `${user.address.street}, ${user.address.city}` : ''}
            </p>
          </div>
      </CardContent>
    </Card>
  )
}

export default UserCardBasic;